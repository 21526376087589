<template>
  <div v-if="type == 'header'" class="row x-gap-20 items-center xxl:d-none">
    <div class="col-auto">
      <div
        data-x-click="currency"
        class="d-flex items-center text-14 pointer"
        v-bind:class="lightBg ? 'text-dark-1' : 'text-white'"
      >
        <span class="js-currencyMenu-mainTitle">{{ default_currency_code }} </span>
        <i class="icon-chevron-sm-down text-7 ml-10"></i>
      </div>
    </div>
  </div>
  <div v-if="type == 'footer'" class="col-auto">
    <div class="d-flex items-center">
      <div class="d-flex items-center text-14 fw-500">
        <i class="icon- text-16 mr-10"> {{ default_symbol }} </i>
        <span class="underline" data-x-click="currency"> {{ default_currency_code }} </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: String,
    lightBg: Number,
    defaultCurrency: Object,
  },
  data: function () {
    return {
      default_currency_code: "INR",
      default_symbol: "₹",
    };
  },
  computed: {},
  mounted: function () {
    if (!this.defaultCurrency) {
      this.fetchIpAddress();
    } else {
      this.default_currency_code = this.defaultCurrency.code;
      this.default_symbol = this.defaultCurrency.symbol;
    }
  },
  methods: {
    fetchIpAddress: async function () {
      var rawResponse = await fetch("https://api.ipify.org/?format=json");
      const response = await rawResponse.json();
      if (response.ip) {
        axios
          .post("/set-default-currency-from-ip", {
            ip: response.ip,
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
          })
          .then((res) => {
            this.default_currency_code = res.data.data.code;
            this.default_symbol = res.data.data.symbol;
          });
      }
    },
  },
};
</script>
