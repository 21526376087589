<template>
  <div id="ModalLoadingSpinner" class="preloader js-preloader" v-if="isShowMoreImageLoading">
    <div class="loading-1"></div>
  </div>
  <div class="col-xl-3 col-lg-4 lg:d-none">
    <aside class="sidebar y-gap-40">
      <div class="sidebar__item -no-border">
        <h5 class="text-18 fw-500 mb-10">Search by property name</h5>
        <div class="single-field relative d-flex items-center py-10">
          <input class="pl-50 border-light text-dark-1 h-50 rounded-8" type="text" placeholder="e.g. Best Western"
            v-model="searchPropertyName" />
          <button class="absolute d-flex items-center h-full">
            <i class="icon-search text-20 px-15 text-dark-1"></i>
          </button>
        </div>
      </div>

      <div class="sidebar__item">
        <h5 class="text-18 fw-500 mb-10">Deals</h5>
        <div class="sidebar-checkbox">
          <div v-for="dealsFilterItem in dealsFilterItems" class="row y-gap-10 items-center justify-between">
            <div class="col-auto">
              <div class="d-flex items-center">
                <div class="form-checkbox">
                  <input type="checkbox" v-model="dealFilters[dealsFilterItem.key]" />
                  <div class="form-checkbox__mark">
                    <div class="form-checkbox__icon icon-check"></div>
                  </div>
                </div>
                <div class="text-15 ml-10">{{ dealsFilterItem.label }}</div>
              </div>
            </div>
            <div class="col-auto">
              <div class="text-15 text-light-1">
                {{ dealFiltersCount[dealsFilterItem.key] }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="sidebar__item">
        <h5 class="text-18 fw-500 mb-10">Popular Filters</h5>
        <div class="sidebar-checkbox">
          <div
            v-for="popularFilterItem in popularFilterItems"
            class="row y-gap-10 items-center justify-between"
          >
            <div class="col-auto">
              <div class="d-flex items-center">
                <div class="form-checkbox">
                  <input
                    type="checkbox"
                    v-model="popularFilters[popularFilterItem.key]"
                  />
                  <div class="form-checkbox__mark">
                    <div class="form-checkbox__icon icon-check"></div>
                  </div>
                </div>
                <div class="text-15 ml-10">{{ popularFilterItem.label }}</div>
              </div>
            </div>

            <div class="col-auto">
              <div class="text-15 text-light-1">
                {{ popularFiltersCount[popularFilterItem.key] }}
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="sidebar__item pb-30">
        <h5 class="text-18 fw-500 mb-10">Nightly Price</h5>
        <div class="row x-gap-10 y-gap-30">
          <div class="col-12">
            <div class="js-price-rangeSlider">
              <div class="text-14 fw-500"></div>

              <div class="d-flex justify-between mb-20">
                <div class="text-15 text-dark-1">
                  <span class="js-lower"></span>
                  -
                  <span class="js-upper"></span>
                </div>
              </div>

              <div class="px-5">
                <div class="js-slider"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sidebar__item">
        <h5 class="text-18 fw-500 mb-10">Amenities</h5>
        <div class="sidebar-checkbox">
          <div v-for="amenityItem in amenityItems" class="row y-gap-10 items-center justify-between">
            <div class="col-auto">
              <div class="d-flex items-center">
                <div class="form-checkbox">
                  <input type="checkbox" v-model="amenitiesFilters[amenityItem.key]" />
                  <div class="form-checkbox__mark">
                    <div class="form-checkbox__icon icon-check"></div>
                  </div>
                </div>
                <div class="text-15 ml-10">{{ amenityItem.label }}</div>
              </div>
            </div>

            <div class="col-auto">
              <div class="text-15 text-light-1">
                {{ amenitiesFiltersCount[amenityItem.key] }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sidebar__item">
        <h5 class="text-18 fw-500 mb-10">Board Basis</h5>
        <div class="sidebar-checkbox">
          <div v-for="boardBasisFilterItem in boardBasisFilterItems" class="row y-gap-10 items-center justify-between">
            <div class="col-auto">
              <div class="d-flex items-center">
                <div class="form-checkbox">
                  <input type="checkbox" v-model="boardBasisFilters[boardBasisFilterItem.key]" />
                  <div class="form-checkbox__mark">
                    <div class="form-checkbox__icon icon-check"></div>
                  </div>
                </div>
                <div class="text-15 ml-10">{{ boardBasisFilterItem.label }}</div>
              </div>
            </div>

            <div class="col-auto">
              <div class="text-15 text-light-1">
                {{ boardBasisFiltersCount[boardBasisFilterItem.key] }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sidebar__item">
        <h5 class="text-18 fw-500 mb-10">Star Rating</h5>
        <div class="sidebar-checkbox">
          <div v-for="ratingItem in ratingItems" class="row y-gap-10 items-center justify-between">
            <div class="col-auto">
              <div class="d-flex items-center">
                <div class="form-checkbox">
                  <input type="checkbox" v-model="ratingFilters[ratingItem.key]" />
                  <div class="form-checkbox__mark">
                    <div class="form-checkbox__icon icon-check"></div>
                  </div>
                </div>
                <div class="text-15 ml-10">{{ ratingItem.label }}</div>
              </div>
            </div>
            <div class="col-auto">
              <div class="text-15 text-light-1">
                {{ ratingFiltersCount[ratingItem.key] }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sidebar__item">
        <div class="sidebar-checkbox">
          <div class="row y-gap-10 items-center justify-between">
            <div class="col-auto"></div>
            <div class="col-auto">
              <div class="text-15 text-light-1">
                <button type="button" class="button -md -dark-1 bg-blue-1 text-white mb-0" @click="applyFilters()">
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
  </div>
  <div class="col-xl-9 col-lg-8">
    <div class="row y-gap-10 items-center justify-between">
      <div class="col-auto">
        <div class="text-18">
          <span class="fw-500" v-if="hotelNotFoundFromApi">Sorry no Properties Found</span>
          <span class="fw-500" v-else-if="totalHotels == 0">Getting properties</span>
          <span class="fw-500" v-else-if="totalHotels > 0">{{ totalHotels }} properties</span>
          in {{ cityName }}
        </div>
      </div>

      <div class="col-auto">
        <div class="row x-gap-20 y-gap-20">
          <div class="col-auto">
            <button class="button -blue-1 h-40 px-20 rounded-100 bg-blue-1-05 text-15 text-blue-1 bg-blue-1 "
              v-bind:class="{ '-is-active': topPicksStatus }" @click="topPicks()">
              <i class="icon-up-down text-14 mr-10"></i>
              Top picks for your search
            </button>
          </div>

          <div class="col-auto d-none lg:d-block">
            <button data-x-click="filterPopup"
              class="button -blue-1 h-40 px-20 rounded-100 bg-blue-1-05 text-15 text-blue-1">
              <i class="icon-up-down text-14 mr-10"></i>
              Filter
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="filterPopup bg-white" data-x="filterPopup" data-x-toggle="-is-active">
      <aside class="sidebar -mobile-filter">
        <div data-x-click="filterPopup" class="-icon-close" id="filter-close">
          <i class="icon-close"></i>
        </div>

        <div class="sidebar__item">
          <h5 class="text-18 fw-500 mb-10">Search by property name</h5>
          <div class="single-field relative d-flex items-center py-10">
            <input class="pl-50 border-light text-dark-1 h-50 rounded-8" type="email" placeholder="e.g. Best Western"
              v-model="searchPropertyName" />
            <button class="absolute d-flex items-center h-full">
              <i class="icon-search text-20 px-15 text-dark-1"></i>
            </button>
          </div>
        </div>

        <div class="sidebar__item">
          <h5 class="text-18 fw-500 mb-10">Deals</h5>
          <div class="sidebar-checkbox">
            <div v-for="dealsFilterItem in dealsFilterItems" class="row y-gap-10 items-center justify-between">
              <div class="col-auto">
                <div class="d-flex items-center">
                  <div class="form-checkbox">
                    <input type="checkbox" v-model="dealFilters[dealsFilterItem.key]" />
                    <div class="form-checkbox__mark">
                      <div class="form-checkbox__icon icon-check"></div>
                    </div>
                  </div>
                  <div class="text-15 ml-10">{{ dealsFilterItem.label }}</div>
                </div>
              </div>
              <div class="col-auto">
                <div class="text-15 text-light-1">
                  {{ dealFiltersCount[dealsFilterItem.key] }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="sidebar__item">
          <h5 class="text-18 fw-500 mb-10">Popular Filters</h5>
          <div class="sidebar-checkbox">
            <div v-for="popularFilterItem in popularFilterItems" class="row items-center justify-between">
              <div class="col-auto">
                <div class="d-flex items-center">
                  <div class="form-checkbox">
                    <input type="checkbox" v-model="popularFilters[popularFilterItem.key]" />
                    <div class="form-checkbox__mark">
                      <div class="form-checkbox__icon icon-check"></div>
                    </div>
                  </div>
                  <div class="text-15 ml-10">{{ popularFilterItem.label }}</div>
                </div>
              </div>

              <div class="col-auto">
                <div class="text-15 text-light-1">
                  {{ popularFiltersCount[popularFilterItem.key] }}
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <div class="sidebar__item pb-30">
          <h5 class="text-18 fw-500 mb-10">Nightly Price</h5>
          <div class="row x-gap-10 y-gap-30">
            <div class="col-12">
              <div class="js-price-rangeSlider">
                <div class="text-14 fw-500"></div>

                <div class="d-flex justify-between mb-20">
                  <div class="text-15 text-dark-1">
                    <span class="js-lower"></span>
                    -
                    <span class="js-upper"></span>
                  </div>
                </div>

                <div class="px-5">
                  <div class="js-slider"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sidebar__item">
          <h5 class="text-18 fw-500 mb-10">Amenities</h5>
          <div class="sidebar-checkbox">
            <div v-for="amenityItem in amenityItems" class="row items-center justify-between">
              <div class="col-auto">
                <div class="d-flex items-center">
                  <div class="form-checkbox">
                    <input type="checkbox" v-model="amenitiesFilters[amenityItem.key]" />
                    <div class="form-checkbox__mark">
                      <div class="form-checkbox__icon icon-check"></div>
                    </div>
                  </div>
                  <div class="text-15 ml-10">{{ amenityItem.label }}</div>
                </div>
              </div>

              <div class="col-auto">
                <div class="text-15 text-light-1">
                  {{ amenitiesFiltersCount[amenityItem.key] }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sidebar__item">
          <h5 class="text-18 fw-500 mb-10">Board Basis</h5>
          <div class="sidebar-checkbox">
            <div v-for="boardBasisFilterItem in boardBasisFilterItems" class="row y-gap-10 items-center justify-between">
              <div class="col-auto">
                <div class="d-flex items-center">
                  <div class="form-checkbox">
                    <input type="checkbox" v-model="boardBasisFilters[boardBasisFilterItem.key]" />
                    <div class="form-checkbox__mark">
                      <div class="form-checkbox__icon icon-check"></div>
                    </div>
                  </div>
                  <div class="text-15 ml-10">{{ boardBasisFilterItem.label }}</div>
                </div>
              </div>

              <div class="col-auto">
                <div class="text-15 text-light-1">
                  {{ boardBasisFiltersCount[boardBasisFilterItem.key] }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sidebar__item">
          <h5 class="text-18 fw-500 mb-10">Star Rating</h5>
          <div class="sidebar-checkbox">
            <div v-for="ratingItem in ratingItems" class="row y-gap-10 items-center justify-between">
              <div class="col-auto">
                <div class="d-flex items-center">
                  <div class="form-checkbox">
                    <input type="checkbox" v-model="ratingFilters[ratingItem.key]" />
                    <div class="form-checkbox__mark">
                      <div class="form-checkbox__icon icon-check"></div>
                    </div>
                  </div>
                  <div class="text-15 ml-10">{{ ratingItem.label }}</div>
                </div>
              </div>
              <div class="col-auto">
                <div class="text-15 text-light-1">
                  {{ ratingFiltersCount[ratingItem.key] }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row x-gap-10 y-gap-30">
          <div class="col-12">
            <div class="text-15 text-light-1">
              <button type="button" class="button -md -dark-1 bg-blue-1 text-white mb-0" @click="applyFilters()">
                Filter
              </button>
            </div>
          </div>
        </div>
      </aside>
    </div>

    <div class="mt-30"></div>
    <!-- <div class="preloader js-preloader" v-if="hotels.length == 0">
      <div class="preloader__wrap">
        <div class="preloader__icon">
          <img :src="assetsPath + 'images/logo/myvagatriplogo-01.svg'" alt="Logo" />
          Hold on, we’re fetching hotels for you
        </div>
      </div>
    </div> -->

    <div id="ModalLoadingSpinner" class="preloader js-preloader"
      v-if="hotels.length == 0 && hotelNotFoundFromApi == false && hotelNotFoundForSelectedFilter == false">
      <div class="ModalLoadingSpinner__content">
        <div class="ModalLoadingSpinner__content--icon">
          <img class="content-img" :src="assetsPath + 'images/logo/myvagatrip_logo.png'" alt="Logo" />
        </div>
        <div class="ModalLoadingSpinner__content--text">
          <h3 class="content-info-header">Hold on, we’re fetching hotels for you</h3>
        </div>
      </div>
    </div>

    <div v-if="hotelNotFoundFromApi" class="row y-gap-30" id="all-hotels-container">
      <div class="text-center items-align-center pt-50 -mt-50">
        <h2>Sorry no hotels found in {{ cityName }} </h2>
      </div>
    </div>

    <div v-if="hotelNotFoundForSelectedFilter" class="row y-gap-30" id="all-hotels-container">
      <div class="text-center items-align-center pt-50 -mt-50">
        <h5> Sorry no hotels found in {{ cityName }} for Selected Filter Try Out another Filters </h5>
      </div>
    </div>

    <div v-if="!isLoading && hotels.length > 0" class="row y-gap-30" id="all-hotels-container">
      <div v-for="hotel in hotels" class="col-12">
        <div class="border-top-light pt-30">
          <div class="row x-gap-20 y-gap-20">
            <div class="col-md-auto">
              <div class="cardImage ratio ratio-1:1 w-250 md:w-1/1 rounded-4">
                <div class="cardImage__content">
                  <img class="rounded-4 col-12 img-fluid" v-bind:alt="hotel.Name"
                    :src="hotel.ThumbImages == 'http://xmlhub.rezlive.com/images/noimage.gif' ? assetsPath + 'front-assets/img/general/image_not_found-01.jpg' : hotel.ThumbImages"
                    @error="setDefaultImage" />

                </div>

                <div class="cardImage__wishlist d-flex">
                  <a class="glightbox js-gallery button -blue-1 bg-white size-30 rounded-full shadow-2"
                    data-gallery="gallery2" @click="showHotelImages(hotel.Id)" style="padding: 3px;">
                    <img :src="assetsPath + 'images/icons/show-more.svg'" alt="Show More" />
                  </a>
                  <button class="button -blue-1 bg-white size-30 rounded-full shadow-2 mx-1"
                    @click="wishList(hotel.Id, wishlistHotels.includes(parseInt(hotel.Id)))" v-bind:class="{
                      'w-selected': wishlistHotels.includes(parseInt(hotel.Id)),
                    }">
                    <i class="icon-heart text-12"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="col-md">
              <h3 class="text-18 lh-16 fw-500 hotel-title-container" >
                <a href="javascript:void(0)" @click="hotelDetail(hotel.Id, hotel.Name)"
                  class="hotel-name">{{ hotel.Name }}</a>
                <div class="d-inline-block ml-10">
                  <template v-for="index in parseInt(hotel.Rating)">
                    <i class="icon-star text-10 text-yellow-2" v-bind:class="{ 'ml-4': index > 0 }">
                    </i>
                  </template>
                </div>
              </h3>

              <div class="row x-gap-10 y-gap-10 items-center city-container">
                <div class="col-auto">
                  <p class="text-14 hotel-city-name">{{ cityName }}</p>
                </div>
              </div>

              <div class="room-detail-container">
                  <div>

                    <div class="text-14 lh-15 md:mt-20 mt-20 sm:mt-5">
                      <div class="fw-500 capitalize">
                        {{ roomTypeName(hotel["RoomDetails"]["RoomDetail"]) }}
                      </div>
                      <div class="text-light-1 capitalize">
                        {{ roomTypeDetails(hotel["RoomDetails"]["RoomDetail"]) }}
                      </div>
                    </div>
      
                    <div class="text-14 text-green-2 lh-15 mt-10" v-if="hotel['RoomDetails']['RoomDetail'][0] &&
                      hotel['RoomDetails']['RoomDetail'][0]['CancellationPolicy'] &&
                      hotel['RoomDetails']['RoomDetail'][0]['CancellationPolicy']['Refundable'] === 'Yes'
                      ">
                      <div class="fw-500">
                        Free cancellation till {{ hotel["RoomDetails"]["RoomDetail"][0]["CancellationPolicy"]["TillDate"] }}
                      </div>
                      <div class="d-none d-sm-block"> You can cancel later, so lock in this great price today. </div>
                    </div>
                    <div class="text-14 text-green-2 lh-15 mt-10" v-if="hotel['RoomDetails']['RoomDetail'] &&
                      hotel['RoomDetails']['RoomDetail']['CancellationPolicy'] &&
                      hotel['RoomDetails']['RoomDetail']['CancellationPolicy']['Refundable'] === 'Yes'
                      ">
                      <div class="fw-500">
                        Free cancellation till
                        {{
                          hotel["RoomDetails"]["RoomDetail"]["CancellationPolicy"]["TillDate"]
                        }}
                      </div>
                      <div class="sm:d-none md:d-block"> You can cancel later, so lock in this great price today. </div>
                    </div>
      
                    <div class="row x-gap-10 y-gap-10 md:pt-20 pt-20 sm:pt-10 sm:pr-10" v-if="hotel.roomAmenities && hotel.roomAmenities.length > 0">
                      <div class="col-auto" v-for="roomAmenities in hotel.roomAmenities">
                        <div class="border-light rounded-100 py-5 px-20 text-14 lh-14 capitalize" :title="roomAmenities">
                          {{ roomAmenities.length > 30 ? roomAmenities.substring(0, 30) + "..." : roomAmenities }}
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="col-md-auto text-right md:text-left">
                    <div class="h-full justify-between d-flex flex-column  ">
                      <div class="text-right">
                        
                        <div class="text-22 sm:text-18 lh-12 fw-600 mt-5">
                          {{ defaultCurrency }} {{ displayPropertyRate(hotel.Price) }}
                        </div>
                        <span class="text-12 text-light-1 xs:d-none sm:d-block ">(inc. GST)</span>
                        <div class="text-14 text-light-1">
                          {{ totalNights }} Night{{ totalNights > 1 ? 's' : '' }}, {{ totalRooms }}
                          Room{{ totalRooms > 1 ? 's' : '' }}
                        </div>
                      
                      </div>
                      <form method="GET" v-bind:id="'hotel_detail_' + hotel.Id" v-bind:action="'/hotel-detail/' + hotel.Id"
                        class="mb-3">
                        <button type="button" class="button -md -dark-1 bg-blue-1 text-white sm:mt-5 mt-24 md:mt-24 mb-0 sm:pl-10 sm:pr-10 see-availability-button"
                          @click="hotelDetail(hotel.Id, hotel.Name)">
                          See Availability
                          <div class="icon-arrow-top-right ml-15"></div>
                        </button>
                      </form>
                    </div>
                  </div>

              </div>

              
            </div>

           
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="profile-description">
      <div class="col-12">
        <div class="pt-30">
          <div class="row x-gap-20 y-gap-20">
            <div class="col-md-auto">
              <div class="cardImage skeleton ratio ratio-1:1 w-250 md:w-1/1 rounded-4">
                <div class="cardImage__content"></div>
              </div>
            </div>
            <div class="col-md">
              <div class="text-18 lh-16 fw-500 skeleton skeleton-text skeleton-body-25"></div>
              <div class="text-18 lh-16 fw-500 skeleton skeleton-text skeleton-body-75"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="pt-30">
          <div class="row x-gap-20 y-gap-20">
            <div class="col-md-auto">
              <div class="cardImage skeleton ratio ratio-1:1 w-250 md:w-1/1 rounded-4">
                <div class="cardImage__content"></div>
              </div>
            </div>
            <div class="col-md">
              <div class="text-18 lh-16 fw-500 skeleton skeleton-text skeleton-body-25"></div>
              <div class="text-18 lh-16 fw-500 skeleton skeleton-text skeleton-body-75"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="pt-30">
          <div class="row x-gap-20 y-gap-20">
            <div class="col-md-auto">
              <div class="cardImage skeleton ratio ratio-1:1 w-250 md:w-1/1 rounded-4">
                <div class="cardImage__content"></div>
              </div>
            </div>
            <div class="col-md">
              <div class="text-18 lh-16 fw-500 skeleton skeleton-text skeleton-body-25"></div>
              <div class="text-18 lh-16 fw-500 skeleton skeleton-text skeleton-body-75"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="pt-30">
          <div class="row x-gap-20 y-gap-20">
            <div class="col-md-auto">
              <div class="cardImage skeleton ratio ratio-1:1 w-250 md:w-1/1 rounded-4">
                <div class="cardImage__content"></div>
              </div>
            </div>
            <div class="col-md">
              <div class="text-18 lh-16 fw-500 skeleton skeleton-text skeleton-body-25"></div>
              <div class="text-18 lh-16 fw-500 skeleton skeleton-text skeleton-body-75"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isLoading && totalPages > 0" class="border-top-light mt-30 pt-30">
      <div class="row x-gap-10 y-gap-20 justify-between md:justify-center">
        <div class="col-auto md:order-1">
          <button class="button -blue-1 size-40 rounded-full border-light" :disabled="page == 1" @click="prev()">
            <i class="icon-chevron-left text-12"></i>
          </button>
        </div>

        <div class="col-md-auto md:order-3">
          <div class="row x-gap-20 y-gap-20 items-center">
            <template v-if="totalPages <= 5">
              <div v-for="n in totalPages" class="col-auto">
                <button class="size-40 flex-center rounded-full" v-bind:class="{ 'bg-dark-1 text-white': page === n }"
                  :disabled="page === n" @click="changePage(n)">
                  {{ n }}
                </button>
              </div>
            </template>
            <template v-if="totalPages > 5">
              <template v-if="page <= 4">
                <div v-for="n in 5" class="col-auto">
                  <button class="size-40 flex-center rounded-full" v-bind:class="{ 'bg-dark-1 text-white': page === n }"
                    :disabled="page === n" @click="changePage(n)">
                    {{ n }}
                  </button>
                </div>
                <div class="col-auto">
                  <div class="size-40 flex-center rounded-full">...</div>
                </div>
                <div class="col-auto">
                  <button class="size-40 flex-center rounded-full" @click="changePage(totalPages - 1)">
                    {{ totalPages - 1 }}
                  </button>
                </div>
                <div class="col-auto">
                  <button class="size-40 flex-center rounded-full" @click="changePage(totalPages)">
                    {{ totalPages }}
                  </button>
                </div>
              </template>
              <template v-else-if="page > 4 && page < totalPages - 4">
                <div class="col-auto">
                  <button class="size-40 flex-center rounded-full" @click="changePage(1)">
                    1
                  </button>
                </div>
                <div class="col-auto">
                  <button class="size-40 flex-center rounded-full" @click="changePage(2)">
                    2
                  </button>
                </div>
                <div class="col-auto">
                  <div class="size-40 flex-center rounded-full">...</div>
                </div>
                <div v-for="n in 4" class="col-auto">
                  <button class="size-40 flex-center rounded-full"
                    v-bind:class="{ 'bg-dark-1 text-white': page === page + n - 2 }" :disabled="page === page + n - 2"
                    @click="changePage(page + n - 2)">
                    {{ page + n - 2 }}
                  </button>
                </div>
                <div class="col-auto">
                  <div class="size-40 flex-center rounded-full">...</div>
                </div>
                <div class="col-auto">
                  <button class="size-40 flex-center rounded-full" @click="changePage(totalPages - 1)">
                    {{ totalPages - 1 }}
                  </button>
                </div>
                <div class="col-auto">
                  <button class="size-40 flex-center rounded-full" @click="changePage(totalPages)">
                    {{ totalPages }}
                  </button>
                </div>
              </template>
              <template v-else>
                <div class="col-auto">
                  <button class="size-40 flex-center rounded-full" @click="changePage(1)">
                    1
                  </button>
                  <!-- json.stringfy() -->
                </div>
                <div class="col-auto">
                  <button class="size-40 flex-center rounded-full" @click="changePage(2)">
                    2
                  </button>
                </div>
                <div class="col-auto">
                  <div class="size-40 flex-center rounded-full">...</div>
                </div>
                <div v-for="n in 5" class="col-auto">
                  <button class="size-40 flex-center rounded-full"
                    v-bind:class="{ 'bg-dark-1 text-white': page === totalPages + n - 5 }"
                    :disabled="page === totalPages + n - 5" @click="changePage(totalPages + n - 5)">
                    {{ totalPages + n - 5 }}
                  </button>
                </div>
              </template>
            </template>
          </div>

          <div class="text-center mt-30 md:mt-10">
            <div class="text-14 text-light-1">
              {{ start + 1 }} – {{ totalResult > end ? end : totalResult }} of
              {{ totalResult }} properties found
            </div>
          </div>
        </div>

        <div class="col-auto md:order-2">
          <button class="button -blue-1 size-40 rounded-full border-light" :disabled="page == totalPages" @click="next()">
            <i class="icon-chevron-right text-12"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    hotelId: Number,
    cityName: String,
    allWishlistHotels: Array,
    cityId: String,
    countryCode: String,
    checkInDate: String,
    checkOutDate: String,
    roomUsers: String,
    userId: Number,
    commissions: Array,
    guestNationality: String,
    guestNationalityCountryCode: String,
  },
  data: function () {
    return {
      dealsFilterItems: [{ key: "freeCancellation", label: "Free cancellation" }],
      isLoading: false,
      totalHotels: 0,
      dealFilters: {
        freeCancellation: false,
      },
      dealFiltersCount: {
        freeCancellation: 0,
      },
      popularFilterItems: [
        { key: "breakfastIncluded", label: "Breakfast Included" },
        { key: "wifiIncluded", label: "Wifi Included" },
        { key: "fiveStar", label: "5 Star" },
      ],
      boardBasisFilterItems: [
        { key: "roomOnly", label: "Room Only" },
        { key: "breakfast", label: "Breakfast" },
        { key: "halfBoard", label: "Half Board" },
        { key: "fullBoard", label: "Full Board" },
      ],
      boardBasisFilters: {
        roomOnly: false,
        breakfast: false,
        halfBoard: false,
        fullBoard: false,
      },
      boardBasisFiltersCount: {
        roomOnly: 0,
        breakfast: 0,
        halfBoard: 0,
        fullBoard: 0,
      },
      popularFilters: {
        breakfastIncluded: false,
        wifiIncluded: false,
        fiveStar: false,
      },
      popularFiltersCount: {
        breakfastIncluded: 0,
        wifiIncluded: 0,
        fiveStar: 0,
      },
      amenityItems: [
        { key: "breakfastIncluded", label: "Breakfast Included" },
        { key: "wifiIncluded", label: "Wifi Included" },
      ],
      amenitiesFilters: {
        breakfastIncluded: false,
        wifiIncluded: false,
      },
      amenitiesFiltersCount: {
        breakfastIncluded: 0,
        wifiIncluded: 0,
      },
      ratingItems: [
        { key: "oneStar", label: "1" },
        { key: "twoStar", label: "2" },
        { key: "threeStar", label: "3" },
        { key: "fourStar", label: "4" },
        { key: "fiveStar", label: "5" },
      ],
      ratingFilters: {
        oneStar: false,
        twoStar: false,
        threeStar: false,
        fourStar: false,
        fiveStar: false,
      },
      ratingFiltersCount: {
        oneStar: 0,
        twoStar: 0,
        threeStar: 0,
        fourStar: 0,
        fiveStar: 0,
      },
      assetsPath: "",
      defaultCurrency: "₹",
      searchPropertyName: "",
      start: 0,
      end: 0,
      pageLength: 15,
      page: 1,
      totalPages: 1,
      hotels: [],
      totalResult: 0,
      minHotelRate: 0,
      maxHotelRate: 0,
      allHotels: [],
      wishlistHotels: [],
      totalRooms: 1,
      totalNights: 1,
      topPicksStatus: true,
      hotelNotFoundFromApi: false,
      GuestNationality: "",
      GuestNationalityCountryCode: "",
      hotelImages: [],
      images: [],
      isShowMoreImageLoading: false,
      hotelNotFoundForSelectedFilter: false,
    };
  },
  computed: {},
  mounted: function () {
    this.assetsPath = window.assetsPath;
    this.defaultCurrency = window.defaultCurrency;
    this.wishlistHotels = this.allWishlistHotels;
    this.GuestNationality = this.guestNationality ? this.guestNationality : null;
    this.GuestNationalityCountryCode = this.guestNationalityCountryCode ? this.guestNationalityCountryCode : null;
    const roomUsers = JSON.parse(this.roomUsers);
    this.totalRooms = roomUsers.length;
    this.GuestNationality = this.guestNationality;
    this.GuestNationalityCountryCode = this.guestNationalityCountryCode;
    var checkInDate = moment(this.checkInDate, "DD/MM/YYYY");
    var checkOutDate = moment(this.checkOutDate, "DD/MM/YYYY");
    this.totalNights = checkOutDate.diff(checkInDate, "days");

    // SCALTEN LOADING
    this.loadData(
      this.hotelId,
      this.cityId,
      this.cityName,
      this.countryCode,
      this.checkInDate,
      this.checkOutDate,
      this.roomUsers,
      this.GuestNationality,
      this.GuestNationalityCountryCode,
    );
  },

  methods: {
    loadData: function (
      hotelId,
      cityId,
      cityName,
      countryCode,
      checkInDate,
      checkOutDate,
      roomUsers,
      guestNationality,
      guestNationalityCountryCode
    ) {
      this.isLoading = true;
      axios
        .get("/get-hotel-list-details/", {
          params: {
            hotelId,
            cityId,
            cityName,
            countryCode,
            checkInDate,
            checkOutDate,
            roomUsers,
            guestNationality,
            guestNationalityCountryCode
          },
        })
        .then((response) => {
          console.log(response);
          this.allHotels = response.data.data.hotels;
          this.hotels = response.data.data.hotels;
          this.totalHotels = response.data.data.total_hotels;
          if (this.totalHotels == 0) {
            this.hotelNotFoundFromApi = true;
          }


          this.page = 1;
          if (this.allHotels.length > 0) {
            this.maxHotelRate = Math.max(...this.allHotels.map((o) => o.Price));
          }

          this.setFilterCount();
          this.priceRangeSliderInit();
          this.setPaginationData();
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    showHotelImages: function (hotelId) {

      if (hotelId != null) {

        this.isShowMoreImageLoading = true;

        axios
          .post("/hotels/" + hotelId)
          .then((response) => {
            console.log(response);
            this.isShowMoreImageLoading = false;
            this.hotelImages = [];

            if (response.data.data.images) {
              // this.hotelImages = response.data.data.images.filter(function (item) {
              //   return item.image_tag != 'Featured Image';
              // }).map(function (item) {
              //   return item.image_url['1000px'] || item.image_url['350px'];
              // });
              // console.log('this.hotelImages', this.hotelImages);
              this.images = response.data.data.images.map(item => item.image_url['1000px'] || item.image_url['350px']);
              console.log(this.images);
              if (this.images[0] != 'http://test.xmlhub.com/images/noimage.gif') {


                // setTimeout(() => {
                //   GLightbox({
                //     selector: ".js-gallery",
                //     touchNavigation: true,
                //     loop: true,
                //     autoplayVideos: true,
                //   });              
                // }, 100);
                let elements = [];

                $.each(this.images, (indexInArray, valueOfElement) => {
                  let str = {};
                  str = {
                    'href': valueOfElement,
                    'type': 'image',
                    'effect': 'fade',
                  };
                  elements.push(str);
                });
                const myGallery = GLightbox({
                  selector: ".js-gallery",
                  elements: elements,
                  autoplayVideos: true,
                  loop: true,
                });
                myGallery.open();

              } else {
                window.fnToast('Sorry no images Provide by Hotel', "error");
              }

            } else {
              window.fnToast('Sorry no images Provide by Hotel', "error");
            }
          })
          .catch((error) => {
            this.isShowMoreImageLoading = false;
            console.log(error);
            // window.fnToast(error, "error");
            window.fnToast('Sorry no images Provide by Hotel', "error");
          });
      }
    },
    displayPropertyRate: function (price) {
      return window.convertToSelectedCurrency(price);
    },

    topPicks: function () {
      this.topPicksStatus = !this.topPicksStatus;
      this.setPaginationData();
    },

    roomTypeName: function (room) {
      var type = this.displayOneRoomInfo(room.Type ? room.Type : room[0].Type);
      let myResult = type.split(",").map((s) => s.trim());
      return myResult[0];
    },

    roomTypeDetails: function (room) {
      var type = this.displayOneRoomInfo(room.Type ? room.Type : room[0].Type);
      let myResult = type.split(",").map((s) => s.trim());
      return myResult[1];
    },

    roomFacilities: function (roomAmenitiesStr) {
      let myResult = roomAmenitiesStr.split(",").map((s) => s.trim());
      const roomAminities = [];
      if (myResult.length >= 4) {
        for (let i = 0; i < 4; i++) {
          roomAminities.push(this.displayOneRoomInfo(myResult[i]));
        }
        return roomAminities;
      } else {
        return myResult;
      }
    },

    displayOneRoomInfo(description) {
      return this.totalRooms > 1 ? description.split("|")[0] : description;
    },

    searchProperty: function () {
      this.page = 1;
      this.setPaginationData(false);
    },

    prev: function () {
      this.page--;
      this.setPaginationData();
    },

    next: function () {
      this.page++;
      this.setPaginationData();
    },

    changePage: function (n) {
      this.page = n;
      this.setPaginationData();
    },

    setPaginationData: function (scroll = true) {
      let searchHotels = Object.assign([], this.allHotels);
      if (this.topPicksStatus) {
        searchHotels.sort((a, b) => parseInt(b.Rating) - parseInt(a.Rating));
      }

      this.start = this.pageLength * (this.page - 1);
      this.end = this.page * this.pageLength;

      if (this.searchPropertyName) {
        searchHotels = this.allHotels.filter((h) =>
          h.Name.toLowerCase().includes(this.searchPropertyName.toLowerCase())
        );
      }

      if (this.minHotelRate || this.maxHotelRate) {
        const minHotelRate = window.convertToOriginalCurrency(this.minHotelRate);
        const maxHotelRate = window.convertToOriginalCurrency(this.maxHotelRate);
        if (this.minHotelRate && this.maxHotelRate) {
          searchHotels = searchHotels.filter(
            (h) => h.Price >= minHotelRate && h.Price <= maxHotelRate
          );
        } else {
          if (maxHotelRate) {
            searchHotels = searchHotels.filter((h) => h.Price <= maxHotelRate);
          } else {
            searchHotels = searchHotels.filter((h) => h.Price >= minHotelRate);
          }
        }
      }

      if (this.dealFilters.freeCancellation) {
        searchHotels = searchHotels.filter((h) => {
          let found = false;
          if (h.Hotelwiseroomcount == 1) {
            if (
              h.RoomDetails.RoomDetail.CancellationPolicy &&
              h.RoomDetails.RoomDetail.CancellationPolicy.Refundable.toLowerCase() != "no"
            ) {
              found = true;
            }
          } else {
            for (let j = 0; j < h.Hotelwiseroomcount; j++) {
              if (
                h.RoomDetails.RoomDetail[j].CancellationPolicy &&
                h.RoomDetails.RoomDetail[j].CancellationPolicy.Refundable.toLowerCase() !=
                "no"
              ) {
                found = true;
                break;
              }
            }
          }
          return found;
        });
      }

      // if (
      //   this.popularFilters.breakfastIncluded ||
      //   this.popularFilters.wifiIncluded ||
      //   this.popularFilters.fiveStar
      // ) {
      //   searchHotels = searchHotels.filter((h) => {
      //     let found = false;
      //     if (h.Hotelwiseroomcount == 1) {
      //       if (
      //         (this.popularFilters.breakfastIncluded &&
      //           h.RoomDetails.RoomDetail.BoardBasis.toLowerCase().includes(
      //             "breakfast"
      //           )) ||
      //         (this.popularFilters.wifiIncluded &&
      //           typeof h.RoomDetails.RoomDetail.RoomDescription == "string" &&
      //           h.RoomDetails.RoomDetail.RoomDescription.toLowerCase().includes(
      //             "wifi"
      //           )) ||
      //         (this.popularFilters.fiveStar && h.Rating == 5)
      //       ) {
      //         found = true;
      //       }
      //     } else {
      //       for (let j = 0; j < h.Hotelwiseroomcount; j++) {
      //         if (
      //           (this.popularFilters.breakfastIncluded &&
      //             h.RoomDetails.RoomDetail[j].BoardBasis.toLowerCase().includes(
      //               "breakfast"
      //             )) ||
      //           (this.popularFilters.wifiIncluded &&
      //             typeof h.RoomDetails.RoomDetail[j].RoomDescription == "string" &&
      //             h.RoomDetails.RoomDetail[j].RoomDescription.toLowerCase().includes(
      //               "wifi"
      //             )) ||
      //           (this.popularFilters.fiveStar && h.Rating == 5)
      //         ) {
      //           found = true;
      //           break;
      //         }
      //       }
      //     }
      //     return found;
      //   });
      // }

      if (
        this.boardBasisFilters.roomOnly ||
        this.boardBasisFilters.breakfast ||
        this.boardBasisFilters.halfBoard ||
        this.boardBasisFilters.fullBoard ||
        this.boardBasisFilters.allInclusive
      ) {
        searchHotels = searchHotels.filter((h) => {
          let found = false;
          if (h.Hotelwiseroomcount == 1) {
            if (
              (this.boardBasisFilters.breakfast &&
                h.RoomDetails.RoomDetail.BoardBasis.toLowerCase().includes("breakfast")) ||
              (this.boardBasisFilters.roomOnly &&
                h.RoomDetails.RoomDetail.BoardBasis.toLowerCase().includes("room only")) ||
              (this.boardBasisFilters.halfBoard &&
                h.RoomDetails.RoomDetail.BoardBasis.toLowerCase().includes("half board")) ||
              (this.boardBasisFilters.fullBoard &&
                h.RoomDetails.RoomDetail.BoardBasis.toLowerCase().includes("full board"))
            ) {
              found = true;
            }
          } else {
            for (let j = 0; j < h.Hotelwiseroomcount; j++) {
              if (
                (this.boardBasisFilters.breakfast &&
                  h.RoomDetails.RoomDetail[j].BoardBasis.toLowerCase().includes("breakfast")) ||
                (this.boardBasisFilters.roomOnly &&
                  h.RoomDetails.RoomDetail[j].BoardBasis.toLowerCase().includes("room only")) ||
                (this.boardBasisFilters.halfBoard &&
                  h.RoomDetails.RoomDetail[j].BoardBasis.toLowerCase().includes("half board")) ||
                (this.boardBasisFilters.fullBoard &&
                  h.RoomDetails.RoomDetail[j].BoardBasis.toLowerCase().includes("full board"))
              ) {
                found = true;
                break;
              }
            }
          }
          return found;
        });
      }

      if (this.amenitiesFilters.breakfastIncluded || this.amenitiesFilters.wifiIncluded) {
        searchHotels = searchHotels.filter((h) => {
          let found = false;
          if (h.Hotelwiseroomcount == 1) {
            if (
              (this.amenitiesFilters.breakfastIncluded &&
                h.RoomDetails.RoomDetail.BoardBasis.toLowerCase().includes("breakfast")) ||
              (this.amenitiesFilters.wifiIncluded &&
                typeof h.RoomDetails.RoomDetail.RoomDescription == "string" &&
                h.RoomDetails.RoomDetail.RoomDescription.toLowerCase().includes("wifi"))
            ) {
              found = true;
            }
          } else {
            for (let j = 0; j < h.Hotelwiseroomcount; j++) {
              if (
                (this.amenitiesFilters.breakfastIncluded &&
                  h.RoomDetails.RoomDetail[j].BoardBasis.toLowerCase().includes("breakfast")) ||
                (this.amenitiesFilters.wifiIncluded &&
                  typeof h.RoomDetails.RoomDetail[j].RoomDescription == "string" &&
                  h.RoomDetails.RoomDetail[j].RoomDescription.toLowerCase().includes("wifi"))
              ) {
                found = true;
                break;
              }
            }
          }
          return found;
        });
      }

      if (
        this.ratingFilters.oneStar ||
        this.ratingFilters.twoStar ||
        this.ratingFilters.threeStar ||
        this.ratingFilters.fourStar ||
        this.ratingFilters.fiveStar
      ) {
        searchHotels = searchHotels.filter(
          (h) =>
            (this.ratingFilters.oneStar && h.Rating == 1) ||
            (this.ratingFilters.twoStar && h.Rating == 2) ||
            (this.ratingFilters.threeStar && h.Rating == 3) ||
            (this.ratingFilters.fourStar && h.Rating == 4) ||
            (this.ratingFilters.fiveStar && h.Rating == 5)
        );
      }

      this.totalPages = Math.ceil(searchHotels.length / this.pageLength);
      this.totalResult = searchHotels.length;
      this.hotels = searchHotels.filter((h, i) => i >= this.start && i < this.end);

      for (const hotel of this.hotels) {
        hotel.roomAmenities = [];
        if (hotel["RoomDetails"]["RoomDetail"]["RoomDescription"]) {
          if (hotel["RoomDetails"]["RoomDetail"]["RoomDescription"] != "") {
            hotel.roomAmenities = this.roomFacilities(
              this.displayOneRoomInfo(
                hotel["RoomDetails"]["RoomDetail"]["RoomDescription"]
              )
            );
          }
        } else {
          if (hotel["RoomDetails"]["RoomDetail"][0]["RoomDescription"] != "") {
            hotel.roomAmenities = this.roomFacilities(
              this.displayOneRoomInfo(
                hotel["RoomDetails"]["RoomDetail"][0]["RoomDescription"]
              )
            );
          }
        }

        if(window.innerWidth<=450 && hotel.roomAmenities.length> 1){
          hotel.roomAmenities = [hotel.roomAmenities[0]]
        }
      }

      if (scroll) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },

    applyFilters: function () {
      this.page = 1;
      this.setPaginationData();
      $('.filterPopup').removeClass('-is-active');
      $('html, body').animate({
        scrollTop: $("#all-hotels-container").offset().top
      }, 1000);
      if (this.hotels.length == 0) {
        this.hotelNotFoundForSelectedFilter = true;
      } else {
        this.hotelNotFoundForSelectedFilter = false;
      }
    },

    priceRangeSliderInit: function () {
      const targets = document.querySelectorAll(".js-price-rangeSlider");

      var maxConvertedPrice = window.convertToSelectedCurrency(this.maxHotelRate);
      targets.forEach((el) => {
        const slider = el.querySelector(".js-slider");
        noUiSlider.create(slider, {
          start: [0, Math.ceil(maxConvertedPrice)],
          step: 1,
          connect: true,
          range: {
            min: parseInt(this.minHotelRate),
            max: Math.ceil(maxConvertedPrice),
          },
          format: {
            to: (value) => {
              return value;
            },
            from: (value) => {
              return value;
            },
          },
        });

        const snapValues = [el.querySelector(".js-lower"), el.querySelector(".js-upper")];
        slider.noUiSlider.on("update", (values, handle) => {
          snapValues[handle].innerHTML = `${this.defaultCurrency} ${parseInt(
            values[handle]
          )}`;
          if (handle === 0) {
            this.minHotelRate = parseInt(values[handle]);
          } else {
            this.maxHotelRate = parseInt(values[handle]);
          }
        });
      });
    },

    setFilterCount: function () {
      let freeCancellationCount = 0;
      let breakfastCount = 0;
      let wifiCount = 0;
      let oneStarCount = 0;
      let twoStarCount = 0;
      let threeStarCount = 0;
      let fourStarCount = 0;
      let fiveStarCount = 0;
      let roomOnlyCount = 0;
      let halfBoardCount = 0;
      let fullBoardCount = 0;

      for (let i = 0; i < this.allHotels.length; i++) {
        if (this.allHotels[i].Hotelwiseroomcount == 1) {
          if (
            this.allHotels[i].RoomDetails.RoomDetail.CancellationPolicy &&
            this.allHotels[
              i
            ].RoomDetails.RoomDetail.CancellationPolicy.Refundable.toLowerCase() != "no"
          ) {
            freeCancellationCount++;
          }
          if (
            this.allHotels[i].RoomDetails.RoomDetail.BoardBasis &&
            this.allHotels[i].RoomDetails.RoomDetail.BoardBasis.toLowerCase().includes("breakfast")
          ) {
            breakfastCount++;
          }
          if (
            this.allHotels[i].RoomDetails.RoomDetail.BoardBasis &&
            this.allHotels[i].RoomDetails.RoomDetail.BoardBasis.toLowerCase().includes("room only")
          ) {
            roomOnlyCount++;
          }
          if (
            this.allHotels[i].RoomDetails.RoomDetail.BoardBasis &&
            this.allHotels[i].RoomDetails.RoomDetail.BoardBasis.toLowerCase().includes("half board")
          ) {
            halfBoardCount++;
          }
          if (
            this.allHotels[i].RoomDetails.RoomDetail.BoardBasis &&
            this.allHotels[i].RoomDetails.RoomDetail.BoardBasis.toLowerCase().includes("full board")
          ) {
            fullBoardCount++;
          }
          if (
            typeof this.allHotels[i].RoomDetails.RoomDetail.RoomDescription == "string" &&
            this.allHotels[i].RoomDetails.RoomDetail.RoomDescription.toLowerCase().includes("wifi")
          ) {
            wifiCount++;
          }

        } else {
          for (let j = 0; j < this.allHotels[i].Hotelwiseroomcount; j++) {
            if (
              this.allHotels[i].RoomDetails.RoomDetail[j].CancellationPolicy &&
              this.allHotels[i].RoomDetails.RoomDetail[j].CancellationPolicy.Refundable.toLowerCase() != "no"
            ) {
              freeCancellationCount++;
              break;
            }
          }
          for (let j = 0; j < this.allHotels[i].Hotelwiseroomcount; j++) {
            if (
              this.allHotels[i].RoomDetails.RoomDetail[j].BoardBasis &&
              this.allHotels[i].RoomDetails.RoomDetail[j].BoardBasis.toLowerCase().includes("breakfast")
            ) {
              breakfastCount++;
              break;
            }
          }
          for (let j = 0; j < this.allHotels[i].Hotelwiseroomcount; j++) {
            if (
              this.allHotels[i].RoomDetails.RoomDetail[j].BoardBasis &&
              this.allHotels[i].RoomDetails.RoomDetail[j].BoardBasis.toLowerCase().includes("room only")
            ) {
              roomOnlyCount++;
              break;
            }
          }
          for (let j = 0; j < this.allHotels[i].Hotelwiseroomcount; j++) {
            if (
              this.allHotels[i].RoomDetails.RoomDetail[j].BoardBasis &&
              this.allHotels[i].RoomDetails.RoomDetail[j].BoardBasis.toLowerCase().includes("half board")
            ) {
              halfBoardCount++;
              break;
            }
          }
          for (let j = 0; j < this.allHotels[i].Hotelwiseroomcount; j++) {
            if (
              this.allHotels[i].RoomDetails.RoomDetail[j].BoardBasis &&
              this.allHotels[i].RoomDetails.RoomDetail[j].BoardBasis.toLowerCase().includes("full board")
            ) {
              fullBoardCount++;
              break;
            }
          }

          for (let j = 0; j < this.allHotels[i].Hotelwiseroomcount; j++) {
            if (
              typeof this.allHotels[i].RoomDetails.RoomDetail[j].RoomDescription == "string" &&
              this.allHotels[i].RoomDetails.RoomDetail[j].RoomDescription.toLowerCase().includes("wifi")
            ) {
              wifiCount++;
              break;
            }
          }
        }

        if (this.allHotels[i].Rating == 1) {
          oneStarCount++;
        }
        if (this.allHotels[i].Rating == 2) {
          twoStarCount++;
        }
        if (this.allHotels[i].Rating == 3) {
          threeStarCount++;
        }
        if (this.allHotels[i].Rating == 4) {
          fourStarCount++;
        }
        if (this.allHotels[i].Rating == 5) {
          fiveStarCount++;
        }
      }

      // Set Deals Filter Count
      this.dealFiltersCount.freeCancellation = freeCancellationCount;

      // Set Popular Filter Count
      // this.popularFiltersCount.breakfastIncluded = breakfastCount;
      // this.popularFiltersCount.wifiIncluded = wifiCount;
      // this.popularFiltersCount.fiveStar = fiveStarCount;

      // Set Board Basis Filter Count
      this.boardBasisFiltersCount.roomOnly = roomOnlyCount;
      this.boardBasisFiltersCount.breakfast = breakfastCount;
      this.boardBasisFiltersCount.halfBoard = halfBoardCount;
      this.boardBasisFiltersCount.fullBoard = fullBoardCount;

      // Set Amenities Filter Count
      this.amenitiesFiltersCount.breakfastIncluded = breakfastCount;
      this.amenitiesFiltersCount.wifiIncluded = wifiCount;

      // Set Amenities Filter Count
      this.ratingFiltersCount.oneStar = oneStarCount;
      this.ratingFiltersCount.twoStar = twoStarCount;
      this.ratingFiltersCount.threeStar = threeStarCount;
      this.ratingFiltersCount.fourStar = fourStarCount;
      this.ratingFiltersCount.fiveStar = fiveStarCount;
    },

    hotelDetail: function (id, name) {
      if (id) {
        $("#hotel_detail_" + id).append(
          `<input type="hidden" name="cityId" value="${this.cityId}" />
          <input type="hidden" name="hotelCityName" value="${name}" />
          <input type="hidden" name="cityName" value="${this.cityName}" />
          <input type="hidden" name="countryCode" value="${this.countryCode}" />
          <input type="hidden" name="checkInDate" value="${this.checkInDate}" />
          <input type="hidden" name="guestNationality" value="${this.GuestNationality}" />
          <input type="hidden" name="guestNationalityCountryCode" value="${this.guestNationalityCountryCode}" />
          <input type="hidden" name="checkOutDate" value="${this.checkOutDate}" />
          <input type="hidden" name="roomUsers" value='${this.roomUsers}' />`
        );
        $("#hotel_detail_" + id).submit();
      }
    },

    wishList: function (hotelId, isWishlistHotel) {
      if (!this.userId) {
        window.location.href = "/login";
      } else {
        axios
          .post("/wishlist", {
            hotelId: hotelId,
            userId: this.userId,
          })
          .then((response) => {
            if (isWishlistHotel) {
              const index = this.wishlistHotels.findIndex((hId) => hId == hotelId);
              this.wishlistHotels.splice(index, 1);
            } else {
              this.wishlistHotels.push(parseInt(hotelId));
            }
            window.fnToast(response.data.message, "success");
          })
          .catch(function (error) {
            window.fnToast(error, "error");
          });
      }
    },

    setDefaultImage: function (e) {
      e.target.src = this.assetsPath + "front-assets/img/general/noimage.gif";

    },
  },
};
</script>
