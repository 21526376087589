<template>
  <section class="layout-pt-lg layout-pb-md">
    <div class="container overflow-hidden">
      <div data-anim="slide-up delay-1" class="row y-gap-20 justify-between items-end">
        <div class="col-auto">
          <div class="sectionTitle -md">
            <h2 class="sectionTitle__title popupular_destination">Book Hotels at Popular Destinations</h2>
            <p class="sectionTitle__text mt-5 sm:mt-0">
              These popular destinations have a lot to offer
            </p>
          </div>
        </div>
      </div>

      <div
        class="relative pt-40 sm:pt-20 js-section-slider"
        data-gap="30"
        data-scrollbar
        data-slider-cols="base-2 xl-4 lg-3 md-2 sm-1 base-1"
        data-anim="slide-up delay-2"
      >
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="popularCity in popularCities">
            <a href="javascript:void(0);" class="citiesCard -type-1 d-block rounded-4" style="border-radius: 18px;">
              <div class="citiesCard__image ratio ratio-3:4">

                <img
                  src="#"
                  v-bind:data-src="assetsPath + 'storage/image/popular-cities/'+popularCity.city_image"
                  v-bind:alt="popularCity.name"
                  class="js-lazy"
                />
              </div>
              <div
                class="citiesCard__content d-flex flex-column justify-between text-center pt-30 pb-20 px-20"
              >
                <div class="citiesCard__bg"></div>
                <div class="citiesCard__top"></div>
                <div class="citiesCard__bottom">
                  <h4 class="text-26 md:text-20 lh-13 text-white mb-30">
                    {{ popularCity.name }}
                  </h4>
                  <form method="get" v-bind:id="'hotel_list_' + popularCity.city_code">
                    <button
                      type="button"
                      class="button col-12 h-40 -blue-1 bg-white text-dark-1"
                      @click="listHotels(popularCity)"
                    >
                      Discover
                    </button>
                  </form>
                </div>
              </div>
            </a>
          </div>
        </div>
        <button
          class="section-slider-nav -prev flex-center button -blue-1 bg-white shadow-1 size-40 rounded-full sm:d-none js-prev"
        >
          <i class="icon icon-chevron-left text-14"></i>
        </button>
        <button
          class="section-slider-nav -next flex-center button -blue-1 bg-white shadow-1 size-40 rounded-full sm:d-none js-next"
        >
          <i class="icon icon-chevron-right text-14"></i>
        </button>
        <div class="slider-scrollbar bg-light-2 mt-40 sm:d-none js-scrollbar"></div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";
import $ from "jquery";

export default {
  props: {
    popularCities: Array,
    clientIp: String,
  },
  data: function () {
    return {
      assetsPath: "",
      userNationality: [],
      guestNationality:"",      
      guestNationalityCountryCode:"",
    };
  },
  computed: {},
  mounted: async function () {
    this.assetsPath = window.assetsPath;
    if (this.clientIp) {
        this.getAllNationalities(this.clientIp);
    } else {
      var rawResponse = await fetch("https://api.ipify.org/?format=json");
      const response = await rawResponse.json();
      if (response.ip) {
        this.getAllNationalities(response.ip);
      }
    }
  },

  methods: {
    getAllNationalities: function (ip) {
      axios({
        method: "post",
        url: '/get-nationalities',
        data: { ip }
      })
        .then((response) => {
          this.userNationality = response.data.data.user_nationality;
          this.guestNationality = this.userNationality['nationality'];
          this.guestNationalityCountryCode = this.userNationality['code'];          
        })
        .catch((error) => {
          window.fnToast(error, "error");
        });
    },
    listHotels: function (city) {
      const roomUsers = [
        {
          adults: 2,
          children: 0,
          childrenAge: [],
        },
      ];
      const checkInDate = moment().add(7, "days").format("DD/MM/YYYY");
      const checkOutDate = moment().add(8, "days").format("DD/MM/YYYY");

      $("#hotel_list_" + city.city_code).append(
        `<input type="hidden" name="hotelId" value="null" />
        <input type="hidden" name="cityId" value="${city.city_code}" />
        <input type="hidden" name="hotelCityName" value="${city.name}" />
        <input type="hidden" name="cityName" value="${city.name}" />
        <input type="hidden" name="countryCode" value="${city.country_code}" />
        <input type="hidden" name="checkInDate" value="${checkInDate}" />
        <input type="hidden" name="checkOutDate" value="${checkOutDate}" />
        <input type="hidden" name="guestNationality" value="${this.guestNationality}" />
        <input type="hidden" name="guestNationalityCountryCode" value="${this.guestNationalityCountryCode}" />
        <input type="hidden" name="roomUsers" value='${JSON.stringify(roomUsers)}' />`
      );

      $("#hotel_list_" + city.city_code).attr("action", `/hotels/${city.country_code}/${city.name}/${city.city_code}`);
      $("#hotel_list_" + city.city_code).submit();
    },
  },
};
</script>
