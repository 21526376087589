<template>
  <form id="booking_detail" action="review-hotel-booking" method="POST" @submit.prevent="submitBookingInfo">
    <div class="row x-gap-20 y-gap-20 pt-20">
      <div class="col-12">
        <div class="form-input">
          <input type="text" name="fullName" v-model="name" required v-on:keypress="isLetter($event)" />
          <label class="lh-1 text-16 text-light-1">Full Name <span class="vaga-required">*</span> </label>
        </div>
        <span v-if="showError && errorMessage.name" class="error">{{ errorMessage.name }}</span>
      </div>
      <div class="col-md-6">
        <div class="form-input">
          <input type="text" name="email" v-model="email" required />
          <label class="lh-1 text-16 text-light-1">Email <span class="vaga-required">*</span> </label>
        </div>
        <span v-if="showError && errorMessage.email" class="error">{{ errorMessage.email }}</span>
      </div>
      <div class="col-md-6">
        <div class="form-input">
          <input type="text" name="phoneNumber" v-model="phoneNumber" required />
          <label class="lh-1 text-16 text-light-1">Phone Number <span class="vaga-required">*</span> </label>
        </div>
        <span v-if="showError && errorMessage.phoneNumber" class="error">{{ errorMessage.phoneNumber }}</span>
      </div>
      <div class="col-6">
        <div class="form-input">
          <input type="text" name="gstNumber" v-model="gstNumber" />
          <label class="lh-1 text-16 text-light-1">GST Number</label>
        </div>
        <span v-if="showError && errorMessage.gstNumber" class="error">{{ errorMessage.gstNumber }}</span>
      </div>
      <div class="col-12">
        <div class="form-input">
          <textarea name="specialRequest" v-model="specialRequest" rows="3"></textarea>
          <label class="lh-1 text-16 text-light-1">Special Requests</label>
        </div>
      </div>
    </div>
    <template v-for="n in roomUsers.length">
      <div class="row x-gap-20 y-gap-20 pt-20 pax-information">
        <div class="col-md-12 pt-0">
          <label class="lh-1 text-16 text-dark-1 fw-600">Room {{ n }}</label>
        </div>
      </div>
      <template v-for="(guestDetail, i) in guestDetails[n - 1]">
        <div class="row x-gap-20 y-gap-20 pt-20 pax-information">
          <div class="col-md-3 pt-0">
            <label v-if="!guestDetail.isChild" class="lh-1 text-16 text-light-1">
              Adult {{ i + 1 }} <span class="vaga-required">*</span>
            </label>
            <label v-if="guestDetail.isChild" class="lh-1 text-16 text-light-1">
              Child <span class="vaga-required">*</span>
            </label>
          </div>
          <div class="col-md-9 pt-0">
            <div class="row x-gap-20">
              <div class="col-md-4 mb-2">
                <select v-if="!guestDetail.isChild" v-model="guestDetail.title" required>
                  <option v-for="title in adultTitles" v-bind:value="title">
                    {{ title }}
                  </option>
                </select>
                <select v-if="guestDetail.isChild" v-model="guestDetail.title" required>
                  <option v-for="title in chilrenTitles" v-bind:value="title">
                    {{ title }}
                  </option>
                </select>
              </div>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-6 mb-2">
                    <input
                      type="text"
                      v-model="guestDetail.firstName"
                      placeholder="First Name"
                      required
                      v-on:keypress="isLetter($event)"
                      v-on:blur="validatePaxDetail()"
                    />
                    <span v-if="showError && !guestDetail.firstName" class="error"> Please enter first name</span>
                  </div>
                  <div class="col-md-6 mb-2">
                    <input
                      type="text"
                      v-model="guestDetail.lastName"
                      placeholder="Last Name"
                      required
                      v-on:keypress="isLetter($event)"
                      v-on:blur="validatePaxDetail()"
                    />
                    <span v-if="showError && !guestDetail.lastName" class="error"> Please enter last name</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <span v-if="guestDetail.duplicate" class="error"> Duplicate Pax Detail </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>

    <div class="w-full h-1 bg-border mt-40 mb-40"></div>

    <div class="mt-30 mb-30 md:mt-32">
      <div class="mt-20">
        <ul class="list-disc y-gap-4 text-15 text-light-1">
          <li>
            The total amount you will be charged is: {{ defaultCurrency }}
            {{ totalBookingAmount }}<span class="text-14 text-light-1"> (inc. GST)</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="row y-gap-20 items-center justify-between">
      <div class="col-auto">
        <div class="d-flex items-center">
          <div class="form-checkbox">
            <input type="checkbox" name="subscribe" v-model="subscribe" />
            <div class="form-checkbox__mark">
              <div class="form-checkbox__icon icon-check"></div>
            </div>
          </div>
          <div class="text-14 lh-10 text-light-1 ml-10">
            Get access to members-only deals, just like the millions of other email subscribers
          </div>
        </div>
        <div class="text-14 text-light-1">
          Review Your Booking Information and verify Information you Provide to Continue Booking...
        </div>
      </div>
    </div>

    <div class="row x-gap-20 y-gap-20 pt-20 items-center justify-end">
      <button type="button" class="button h-60 px-24 -dark-1 bg-blue-1 text-white w-auto" @click="goBackHotelDetail()">
        <div class="icon-arrow-left mr-15"></div>
        Back
      </button>
      <button type="submit" class="button h-60 px-24 -dark-1 bg-blue-1 text-white w-auto ml-15" @click="showErrorMessage()">
        Review Booking
        <div class="icon-arrow-top-right ml-15"></div>
      </button>
    </div>
  </form>
  <form id="hotel_detail" method="get" v-bind:action="'/hotel-detail/' + hotelDetail.HotelId"></form>
</template>

<script>
export default {
  props: {
    user: Object,
    hotelDetail: Object,
    preBookDetail: Object,
    preBookResponse: Object,
    gstPercentage: Number,
    inputs: Object,
  },
  data: function () {
    return {
      privacyPolicy: "",
      termsConditions: "",
      bookingActionUrl: "",
      defaultCurrency: "₹",
      name: "",
      email: "",
      phoneNumber: "",
      gstNumber: "",
      specialRequest: "",
      subscribe: false,
      guestDetails: [],
      errorMessage: {},
      roomUsers: [
        {
          adults: 2,
          children: 0,
          childrenAge: [],
        },
      ],
      disabled: [true, true, true, false, true],
      showError: false,
      adultTitles: ["Mr", "Ms", "Mrs"],
      chilrenTitles: ["Mr", "Ms"],
      totalBookingAmount: 0,
      guestNationalityCountryCode: null,
    };
  },
  computed: {},
  mounted: function () {
    this.defaultCurrency = window.defaultCurrency;
    this.guestNationalityCountryCode = this.inputs.guestNationalityCountryCode;
    this.privacyPolicy = window.privacyPolicy;
    this.termsConditions = window.termsConditions;
    if (this.user && this.user.id) {
      this.name = `${this.user.first_name} ${this.user.last_name}`;
      this.email = this.user.email;
      this.phoneNumber = this.user.mobile;
    }
    this.roomUsers = JSON.parse(this.inputs.roomUsers);

    for (let i = 0; i < this.roomUsers.length; i++) {
      if (!this.guestDetails[i]) {
        this.guestDetails[i] = [];
      }
      for (let j = 0; j < this.roomUsers[i].adults; j++) {
        this.guestDetails[i].push({
          title: "Mr",
          firstName: "",
          lastName: "",
          isChild: false,
          duplicate: false,
        });
      }
      for (let j = 0; j < this.roomUsers[i].children; j++) {
        if (!this.guestDetails[i]) {
          this.guestDetails[i] = [];
        }
        this.guestDetails[i].push({
          title: "Mr",
          firstName: "",
          lastName: "",
          isChild: true,
          age: this.roomUsers[i].childrenAge[j],
          duplicate: false,
        });
      }
    }

    setTimeout(() => {
      this.totalBookingAmount = window.convertToSelectedCurrency(this.preBookResponse.TotalBookingAmount);
    }, 1000);
  },

  methods: {
    displayOneRoomInfo(totalRooms, description) {
      return totalRooms > 1 ? description.split("|")[0] : description;
    },

    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z ]+$/.test(char)) {
        return true;
      } else {
        // Match with regex
        e.preventDefault(); // If not match, don't add to input text
      }
    },

    validateFullName(value) {
      if (!value) {
        this.errorMessage["name"] = "Please enter full name";
        this.disabled = [true, this.disabled[1], this.disabled[2], this.disabled[3], this.disabled[4]];
      } else {
        this.errorMessage["name"] = "";
        this.disabled = [false, this.disabled[1], this.disabled[2], this.disabled[3], this.disabled[4]];
      }
    },

    validateEmail(value) {
      if (!value) {
        this.errorMessage["email"] = "Please enter email address";
        this.disabled = [this.disabled[0], false, this.disabled[2], this.disabled[3], this.disabled[4]];
      } else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        this.errorMessage["email"] = "";
        this.disabled = [this.disabled[0], false, this.disabled[2], this.disabled[3], this.disabled[4]];
      } else {
        this.errorMessage["email"] = "Invalid email address";
        this.disabled = [this.disabled[0], true, this.disabled[2], this.disabled[3], this.disabled[4]];
      }
    },

    validatePhoneNumber(value) {
      if (!value) {
        this.errorMessage["phoneNumber"] = "Please enter phone number! Should be like +919909XXXXXXX";
        this.disabled = [this.disabled[0], this.disabled[1], true, this.disabled[3], this.disabled[4]];
      } else if (/^\+?[1-9][0-9]{7,14}$/.test(value)) {
        this.errorMessage["phoneNumber"] = "";
        this.disabled = [this.disabled[0], this.disabled[1], false, this.disabled[3], this.disabled[4]];
      } else {
        this.errorMessage["phoneNumber"] = "Invalid phone number!";
        this.disabled = [this.disabled[0], this.disabled[1], true, this.disabled[3], this.disabled[4]];
      }
    },

    validateGstNumber(value) {
      if (/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(value) || !value) {
        this.errorMessage["gstNumber"] = "";
        this.disabled = [this.disabled[0], this.disabled[1], this.disabled[2], false, this.disabled[4]];
      } else {
        this.errorMessage["gstNumber"] = "Invalid gst number!";
        this.disabled = [this.disabled[0], this.disabled[1], this.disabled[2], true, this.disabled[4]];
      }
    },

    validatePaxDetail() {
      for (let i = 0; i < this.guestDetails.length; i++) {
        for (let j = 0; j < this.guestDetails[i].length; j++) {
          this.guestDetails[i][j].duplicate = false;
        }
      }

      for (let i = 0; i < this.guestDetails.length; i++) {
        for (let j = 0; j < this.guestDetails[i].length; j++) {
          const { firstName, lastName } = this.guestDetails[i][j];
          const fullName = firstName.trim() + "" + lastName.trim();
          if (firstName && lastName) {
            this.findDuplicate(i, j, fullName);
          }
        }
      }

      var duplicateExist = false;
      for (let i = 0; i < this.guestDetails.length; i++) {
        for (let j = 0; j < this.guestDetails[i].length; j++) {
          if (this.guestDetails[i][j].duplicate) {
            duplicateExist = true;
          }
        }
      }
      if (duplicateExist) {
        this.disabled = [this.disabled[0], this.disabled[1], this.disabled[2], this.disabled[3], true];
      } else {
        this.disabled = [this.disabled[0], this.disabled[1], this.disabled[2], this.disabled[3], false];
      }
    },

    findDuplicate(i, j, currentFullName) {
      for (let index = 0; index < this.guestDetails.length; index++) {
        for (let paxIndex = 0; paxIndex < this.guestDetails[index].length; paxIndex++) {
          if (index === i && paxIndex === j) {
            continue;
          } else {
            const { firstName, lastName } = this.guestDetails[index][paxIndex];
            var fullName = firstName.trim() + "" + lastName.trim();
            if (fullName === currentFullName) {
              this.guestDetails[i][j].duplicate = true;
              this.guestDetails[index][paxIndex].duplicate = true;
            }
          }
        }
      }
    },

    replaceQuotes(string) {
      return string.replace(/\\/g, "").replace(/'/g, "&quot;").replace(/"/g, "&quot;");
    },

    showErrorMessage() {
      if (!this.disabled.every((i) => i === false)) {
        this.validateFullName(this.name);
        this.validateEmail(this.email);
        this.validatePhoneNumber(this.phoneNumber);
        this.showError = true;
        return false;
      } else {
        return true;
      }
    },

    submitBookingInfo() {
      if (!this.showErrorMessage()) {
        return;
      }
      const roomDetail = this.preBookDetail.RoomDetails.RoomDetail;

      let termsAndConditions = roomDetail.TermsAndConditions ? this.replaceQuotes(roomDetail.TermsAndConditions) : "";
      delete roomDetail.TermsAndConditions;
      var token = $('meta[name="csrf-token"]').attr("content");
      $("#booking_detail").append(`
        <input type="hidden" name="_token" value="${token}" />
        <input type="hidden" name="searchSessionId" value="${this.inputs.searchSessionId}" />
        <input type="hidden" name="hotelId" value="${this.inputs.hotelId}" />
        <input type="hidden" name="hotelCityName" value="${this.inputs.hotelCityName}" />
        <input type="hidden" name="cityId" value="${this.inputs.cityId}" />
        <input type="hidden" name="countryCode" value="${this.inputs.countryCode}" />
        <input type="hidden" name="hotelAddress" value="${this.hotelDetail.HotelAddress}" />
        <input type="hidden" name="phone" value="${this.hotelDetail.Phone}" />
        <input type="hidden" name="city" value="${this.hotelDetail.City}" />
        <input type="hidden" name="rating" value="${this.hotelDetail.Rating}" />
        <input type="hidden" name="mainImage" value="${this.hotelDetail.MainImage}" />
        <input type="hidden" name="guestNationality" value="${this.inputs.guestNationality}" />
        <input type="hidden" name="guestNationalityCountryCode" value="${this.inputs.guestNationalityCountryCode}" />
        <input type="hidden" name="bookingAfterPrice" value="${this.preBookResponse.TotalBookingAmount}" />
        <input type="hidden" name="cancellationPolicy" value='${JSON.stringify(
          this.preBookDetail["CancellationInformations"]
        )}' />
        <input type="hidden" name="roomUsers" value='${JSON.stringify(this.roomUsers)}' />
        <input type="hidden" name="checkInDate" value="${this.inputs.checkInDate}" />
        <input type="hidden" name="checkOutDate" value="${this.inputs.checkOutDate}" />
        <input type="hidden" name="roomDetail" value='${JSON.stringify(roomDetail)}' />
        <input type="hidden" name="termsAndConditions" value='${termsAndConditions}' />
        <input type="hidden" name="guestDetails" value='${JSON.stringify(this.guestDetails)}' />
      `);
      $("#booking_detail").submit();
    },

    goBackHotelDetail() {
      $("#hotel_detail").append(
        `<input type="hidden" name="cityId" value="${this.inputs.cityId}" />
        <input type="hidden" name="guestNationality" value="${this.inputs.guestNationality}" />
        <input type="hidden" name="guestNationalityCountryCode" value="${this.inputs.guestNationalityCountryCode}" />
        <input type="hidden" name="hotelCityName" value="${this.inputs.hotelCityName}" />
        <input type="hidden" name="cityName" value="${this.inputs.cityName}" />
        <input type="hidden" name="countryCode" value="${this.inputs.countryCode}" />
        <input type="hidden" name="checkInDate" value="${this.inputs.checkInDate}" />
        <input type="hidden" name="checkOutDate" value="${this.inputs.checkOutDate}" />
        <input type="hidden" name="roomUsers" value='${JSON.stringify(this.roomUsers)}' />`
      );
      $("#hotel_detail").submit();
    },
  },

  watch: {
    name(value) {
      this.validateFullName(value);
    },
    email(value) {
      this.validateEmail(value);
    },
    phoneNumber(value) {
      this.validatePhoneNumber(value);
    },
    gstNumber(value) {
      this.validateGstNumber(value);
    },
  },
};
</script>
