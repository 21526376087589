import './bootstrap';
import { createApp } from 'vue';
import { VueMultiImageUpload } from '@zakerxa/vue-multiple-image-upload';
import HotelSearchComponent from './components/HotelSearch.vue';
import ReviewBookingComponent from './components/ReviewBooking.vue';
import HotelListItemsComponent from './components/HotelListItems.vue';
import WishListItemsComponent from './components/WishListItems.vue';
import RoomListComponent from './components/RoomList.vue';
import BookingUserInfo from './components/BookingUserInfo.vue';
import HotelDetailsComponent from './components/HotelDetails.vue';
import ImageViewer from './components/ImageViewer.vue';
import UserProfileBookings from './components/UserProfileBookings.vue';
import PopularDestination from './components/PopularDestination.vue';
import CurrencyComponent from './components/Currency.vue';
import debounce from './debouncer';
import moment from "moment";
import vue3StarRatings from "vue3-star-ratings";

const app = createApp({})

app.directive('debounce', (el, binding) => debounce(el, binding))
app.component('hotel-search-component', HotelSearchComponent);
app.component('image-viewer', ImageViewer);
app.component('hotel-list-items-component', HotelListItemsComponent);
app.component('wish-list-items-component', WishListItemsComponent);
app.component('room-list-component', RoomListComponent);
app.component('booking-user-info-component', BookingUserInfo);
app.component('hotel-details-component', HotelDetailsComponent);
app.component('user-profile-bookings', UserProfileBookings);
app.component('popular-destination', PopularDestination);
app.component('review-booking-component', ReviewBookingComponent);
app.component('currency-component', CurrencyComponent);
app.component("vue3-star-ratings", vue3StarRatings);
app.component("vue-multi-image-upload, VueMultiImageUpload");


// Date Range Picker
app.component('date-picker', {
    template: `<input v-model="checkInOutDateStr" class="text-light-1" />`,
    props: ['defaultDate', 'searchCheckInDate', 'searchCheckOutDate'],
    data: function() {
        return {
            checkInDate: "",
            checkOutDate: "",
            checkInOutDateStr: "",
        }
    },
    mounted: function() {
        if (!this.defaultDate) {
            this.initializeDateRangePicker();
        }
    },
    methods: {
        initializeDateRangePicker: function() {
            this.checkInDate = this.searchCheckInDate ? this.searchCheckInDate : moment().add(7, "days");
            this.checkOutDate = this.searchCheckOutDate ? this.searchCheckOutDate : moment().add(8, "days");
            this.checkInOutDateStr = this.checkInDate.format("DD MMM YYYY") + " - " + this.checkOutDate.format("DD MMM YYYY");

            var self = this;
            $(this.$el).flatpickr({
                mode: 'range',
                dateFormat: 'd-m-Y',
                altFormat: 'F j, Y',
                minDate: 'today',
                defaultDate: [this.checkInDate.format("DD-MM-YYYY"), this.checkOutDate.format("DD-MM-YYYY")],
                formatDate: (date) => {
                    return moment(date).format('DD-MM-YYYY');
                },
                onChange: function(selectedDates, dateStr, instance) {
                    if (selectedDates.length == 2) {
                        this.checkInDate = moment(selectedDates[0]);
                        this.checkOutDate = moment(selectedDates[1]);
                        this.checkInOutDateStr = this.checkInDate.format("DD MMM YYYY") + " - " + this.checkOutDate.format("DD MMM YYYY");

                        self.$emit('change-date', [this.checkInDate, this.checkOutDate]);
                        return instance.element.value = dateStr.replace('to', ' - ');
                    }
                },
            });
        }
    },
    watch: {
        searchCheckInDate: function(newVal, oldVal) {
            if (oldVal != newVal) {
                if (this.searchCheckInDate && this.searchCheckOutDate) {
                    this.initializeDateRangePicker();
                }
            }
        },
        searchCheckOutDate: function(newVal, oldVal) {
            if (oldVal != newVal) {
                if (this.searchCheckInDate && this.searchCheckOutDate) {
                    this.initializeDateRangePicker();
                }
            }
        }
    },
    beforeDestroy: function() {
        $(this.$el).flatpickr('hide').flatpickr('destroy');
    }
});

app.mount('#app')