<template>
  <div class="tabs -underline-2 js-tabs">
    <div class="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">
      <div class="col-auto">
        <button
          class="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button is-tab-el-active"
          data-tab-target=".-tab-item-1"
        >
          All Booking
        </button>
      </div>
      <div class="col-auto">
        <button
          class="tabs__button text-18 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button"
          data-tab-target=".-tab-item-2"
        >
          Refunds
        </button>
      </div>
    </div>

    <div class="tabs__content pt-30 js-tabs-content">
      <div class="tabs__pane -tab-item-1 is-tab-el-active">
        <div class="overflow-scroll scroll-bar-1">
          <table class="table-responsive table-3 -border-bottom col-12">
            <thead class="bg-light-2">
              <tr>
                <th>Booking Id</th>
                <th>Hotel Name</th>
                <th>Booking Date</th>
                <!-- <th>CheckOut Date</th> -->
                <th>Total Rooms</th>
                <th>Total Amount</th>
                <th>Order Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody v-if="bookings">
              <tr v-for="booking in bookings">
                <td>
                  <span v-if="booking.booking_code">{{ booking.booking_code }}</span
                  ><span v-else>-</span>
                </td>
                <td>
                  <form
                    method="get"
                    v-bind:id="'hotel_detail_' + booking.id"
                    v-bind:action="'/hotel-detail/' + booking.hotel_id"
                  >
                    <a class="d-block js-dropdown-link" @click="hotelDetail(booking)">{{
                      booking.hotel_name
                    }}</a>
                  </form>
                </td>
                <td>
                  {{ formateDate(booking.checkin_date) }} -
                  {{ formateDate(booking.checkout_date) }}
                </td>
                <!-- <td>{{ booking.checkout_date }}</td> -->
                <td>{{ booking.total_rooms }}</td>
                <td>{{ booking.currency_symbol }} {{ booking.total_amount }}</td>
                <td>{{ formateDate(booking.created_at) }}</td>
                <td>
                  <span
                    class="rounded-100 py-4 px-10 text-center text-14 fw-500 bg-yellow-4 text-yellow-3 uppercase"
                  >
                    {{ booking["booking_status"].toLowerCase() }}
                  </span>
                </td>
                <td>
                  <div
                    class="dropdown js-dropdown js-actions-active"
                    @click="toggleDropdownMenu($event.currentTarget)"
                  >
                    <div
                      class="dropdown__button d-flex items-center rounded-4 text-blue-1 bg-blue-1-05 text-14 px-15 py-5"
                      v-if="booking.booking_status === 'confirmed'"
                    >
                      <span class="js-dropdown-title">Actions</span>
                      <i class="icon icon-chevron-sm-down text-7 ml-10"></i>
                    </div>
                    <div v-else>-</div>
                    <div
                      class="toggle-element -dropdown-2 js-click-dropdown"
                      :class="{ '-is-el-visible': isToggled }"
                    >
                      <div class="text-14 fw-500 js-dropdown-list">
                        <form
                          method="get"
                          v-bind:id="'hotel_detail_' + booking.id"
                          v-bind:action="'/hotel-detail/' + booking.hotel_id"
                        >
                          <a
                            class="d-block js-dropdown-link"
                            @click="hotelDetail(booking)"
                            >Details</a
                          >
                        </form>
                        <div v-if="booking.booking_status === 'confirmed'">
                          <a
                            @click="downloadInvoice(booking.booking_id)"
                            class="d-block js-dropdown-link invoice"
                            >Invoice</a
                          >
                        </div>
                        <template v-if="is_date_passed(booking.checkout_date)">
                          <div
                            v-if="
                              booking.booking_status === 'confirmed' &&
                              notApprovedCustomerReviews.includes(booking.id.toString())
                            "
                          >
                            <a
                              @click="writeReview(booking.id, booking.hotel_id)"
                              class="d-block js-dropdown-link invoice"
                              >
                              Review
                            </a>
                          </div>
                          <div
                            v-else-if="
                              booking.booking_status === 'confirmed' &&
                              approvedCustomerReviews.includes(booking.id.toString())
                            "
                          >
                            <a
                              @click="approvedReviews(booking.id, booking.hotel_id)"
                              class="d-block js-dropdown-link invoice"
                              >Review</a
                            >
                          </div>
                          <div v-else-if="booking.booking_status === 'confirmed'">
                            <a
                              @click="writeReview(booking.id, booking.hotel_id)"
                              class="d-block js-dropdown-link invoice"
                              >Review
                            </a>
                          </div>
                        </template>

                        <template v-if="!is_date_passed(booking.checkout_date)">
                          <div v-if="booking.booking_status === 'confirmed'">
                            <a
                              @click="cancelBookingWarning(booking)"
                              class="d-block js-dropdown-link"
                            >
                              Cancel
                            </a>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div v-if="totalPages > 0" class="border-top-light mt-30 pt-30">
            <div class="row x-gap-10 y-gap-20 justify-between md:justify-center">
              <div class="col-auto md:order-1">
                <button
                  class="button -blue-1 size-40 rounded-full border-light"
                  :disabled="page == 1"
                  @click="prev()"
                >
                  <i class="icon-chevron-left text-12"></i>
                </button>
              </div>

              <div class="col-md-auto md:order-3">
                <div class="row x-gap-20 y-gap-20 items-center">
                  <template v-if="totalPages <= 5">
                    <div v-for="n in totalPages" class="col-auto">
                      <button
                        class="size-40 flex-center rounded-full"
                        v-bind:class="{ 'bg-dark-1 text-white': page === n }"
                        :disabled="page === n"
                        @click="changePage(n)"
                      >
                        {{ n }}
                      </button>
                    </div>
                  </template>
                  <template v-if="totalPages > 5">
                    <template v-if="page <= 4">
                      <div v-for="n in 5" class="col-auto">
                        <button
                          class="size-40 flex-center rounded-full"
                          v-bind:class="{ 'bg-dark-1 text-white': page === n }"
                          :disabled="page === n"
                          @click="changePage(n)"
                        >
                          {{ n }}
                        </button>
                      </div>
                      <div class="col-auto">
                        <div class="size-40 flex-center rounded-full">...</div>
                      </div>
                      <div class="col-auto">
                        <button
                          class="size-40 flex-center rounded-full"
                          @click="changePage(totalPages - 1)"
                        >
                          {{ totalPages - 1 }}
                        </button>
                      </div>
                      <div class="col-auto">
                        <button
                          class="size-40 flex-center rounded-full"
                          @click="changePage(totalPages)"
                        >
                          {{ totalPages }}
                        </button>
                      </div>
                    </template>
                    <template v-else-if="page > 4 && page < totalPages - 4">
                      <div class="col-auto">
                        <button
                          class="size-40 flex-center rounded-full"
                          @click="changePage(1)"
                        >
                          1
                        </button>
                      </div>
                      <div class="col-auto">
                        <button
                          class="size-40 flex-center rounded-full"
                          @click="changePage(2)"
                        >
                          2
                        </button>
                      </div>
                      <div class="col-auto">
                        <div class="size-40 flex-center rounded-full">...</div>
                      </div>
                      <div v-for="n in 4" class="col-auto">
                        <button
                          class="size-40 flex-center rounded-full"
                          v-bind:class="{ 'bg-dark-1 text-white': page === page + n - 2 }"
                          :disabled="page === page + n - 2"
                          @click="changePage(page + n - 2)"
                        >
                          {{ page + n - 2 }}
                        </button>
                      </div>
                      <div class="col-auto">
                        <div class="size-40 flex-center rounded-full">...</div>
                      </div>
                      <div class="col-auto">
                        <button
                          class="size-40 flex-center rounded-full"
                          @click="changePage(totalPages - 1)"
                        >
                          {{ totalPages - 1 }}
                        </button>
                      </div>
                      <div class="col-auto">
                        <button
                          class="size-40 flex-center rounded-full"
                          @click="changePage(totalPages)"
                        >
                          {{ totalPages }}
                        </button>
                      </div>
                    </template>
                    <template v-else>
                      <div class="col-auto">
                        <button
                          class="size-40 flex-center rounded-full"
                          @click="changePage(1)"
                        >
                          1
                        </button>
                      </div>
                      <div class="col-auto">
                        <button
                          class="size-40 flex-center rounded-full"
                          @click="changePage(2)"
                        >
                          2
                        </button>
                      </div>
                      <div class="col-auto">
                        <div class="size-40 flex-center rounded-full">...</div>
                      </div>
                      <div v-for="n in 5" class="col-auto">
                        <button
                          class="size-40 flex-center rounded-full"
                          v-bind:class="{
                            'bg-dark-1 text-white': page === totalPages + n - 5,
                          }"
                          :disabled="page === totalPages + n - 5"
                          @click="changePage(totalPages + n - 5)"
                        >
                          {{ totalPages + n - 5 }}
                        </button>
                      </div>
                    </template>
                  </template>
                </div>

                <div class="text-center mt-30 md:mt-10">
                  <div class="text-14 text-light-1">
                    {{ start + 1 }} – {{ totalBookings > end ? end : totalBookings }} of
                    {{ totalBookings }} properties found
                  </div>
                </div>
              </div>

              <div class="col-auto md:order-2">
                <button
                  class="button -blue-1 size-40 rounded-full border-light"
                  :disabled="page == totalPages"
                  @click="next()"
                >
                  <i class="icon-chevron-right text-12"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tabs__pane -tab-item-2">
        <!-- <div class="col-xl-9">
          <form id="formRegister">
            <div class="row x-gap-20 y-gap-20">
              <div class="col-12">
                <div class="form-input">
                  <input
                    type="password"
                    id="current_password"
                    name="current_password"
                    required
                  />
                  <label class="lh-1 text-16 text-light-1">Current Password</label>
                </div>
                <span id="result"></span>
                <div id="current_password_validate"></div>
              </div>

              <div class="col-12">
                <div class="form-input">
                  <input
                    type="password"
                    id="newpassword"
                    name="new_password"
                    required
                    required="required"
                    minlength="8"
                    passwordCheck="passwordCheck"
                    data-error="#errNm1"
                    disabled
                  />
                  <label class="lh-1 text-16 text-light-1">New Password</label>
                </div>
                <div class="errorTxt" id="errNm1"></div>
                <div id="new_password_validate" style="color: red"></div>
              </div>

              <div class="col-12">
                <div class="form-input">
                  <input
                    type="password"
                    id="confirmpassword"
                    name="new_password_again"
                    required
                    disabled
                  />
                  <label class="lh-1 text-16 text-light-1">New Password Again</label>
                </div>
                <span id="result2"></span>
                <div id="new_password_again_validate" style="color: red"></div>
              </div>
              <div class="col-12">
                <div class="row x-gap-10 y-gap-10">
                  <div class="col-auto">
                    <a
                      href="#"
                      class="button h-50 px-24 -dark-1 bg-blue-1 text-white save-data"
                    >
                      Save Changes
                      <div class="icon-arrow-top-right ml-15"></div>
                    </a>
                  </div>
                  <div class="col-auto">
                    <button class="button h-50 px-24 -blue-1 bg-blue-1-05 text-blue-1">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div> -->
        <div class="overflow-scroll scroll-bar-1">
          <table class="table-responsive table-3 -border-bottom col-12">
            <thead class="bg-light-2">
              <tr>
                <th>Booking Id</th>
                <th>Hotel Name</th>
                <th>Booking Date</th>
                <!-- <th>CheckOut Date</th> -->
                <th>Total Rooms</th>
                <th>Total Amount</th>
                <th>Order Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody v-if="refunds">
              <tr v-for="refund in refunds">
                <td>
                  <span v-if="refund.booking_code">{{ refund.booking_code }}</span
                  ><span v-else>-</span>
                </td>
                <td>
                  <form
                    method="get"
                    v-bind:id="'hotel_detail_' + refund.id"
                    v-bind:action="'/hotel-detail/' + refund.hotel_id"
                  >
                    <a class="d-block js-dropdown-link" @click="hotelDetail(refund)">{{
                      refund.hotel_name
                    }}</a>
                  </form>
                </td>
                <td>
                  {{ formateDate(refund.checkin_date) }} -
                  {{ formateDate(refund.checkout_date) }}
                </td>
                <td>{{ refund.total_rooms }}</td>
                <td>{{ refund.currency_symbol }} {{ refund.total_amount }}</td>
                <td>{{ formateDate(refund.created_at) }}</td>
                <td>
                  <span
                    class="rounded-100 py-4 px-10 text-center text-14 fw-500 bg-yellow-4 text-yellow-3 uppercase"
                  >
                    {{ refund["booking_status"].toLowerCase() }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="totalRefundPages > 0" class="border-top-light mt-30 pt-30">
          <div class="row x-gap-10 y-gap-20 justify-between md:justify-center">
            <div class="col-auto md:order-1">
              <button
                class="button -blue-1 size-40 rounded-full border-light"
                :disabled="refundPage == 1"
                @click="refundPrev()"
              >
                <i class="icon-chevron-left text-12"></i>
              </button>
            </div>

            <div class="col-md-auto md:order-3">
              <div class="row x-gap-20 y-gap-20 items-center">
                <template v-if="totalRefundPages <= 5">
                  <div v-for="n in totalRefundPages" class="col-auto">
                    <button
                      class="size-40 flex-center rounded-full"
                      v-bind:class="{ 'bg-dark-1 text-white': refundPage === n }"
                      :disabled="refundPage === n"
                      @click="refundChangePage(n)"
                    >
                      {{ n }}
                    </button>
                  </div>
                </template>
                <template v-if="totalRefundPages > 5">
                  <template v-if="refundPage <= 4">
                    <div v-for="n in 5" class="col-auto">
                      <button
                        class="size-40 flex-center rounded-full"
                        v-bind:class="{ 'bg-dark-1 text-white': refundPage === n }"
                        :disabled="refundPage === n"
                        @click="refundChangePage(n)"
                      >
                        {{ n }}
                      </button>
                    </div>
                    <div class="col-auto">
                      <div class="size-40 flex-center rounded-full">...</div>
                    </div>
                    <div class="col-auto">
                      <button
                        class="size-40 flex-center rounded-full"
                        @click="refundChangePage(totalRefundPages - 1)"
                      >
                        {{ totalRefundPages - 1 }}
                      </button>
                    </div>
                    <div class="col-auto">
                      <button
                        class="size-40 flex-center rounded-full"
                        @click="refundChangePage(totalRefundPages)"
                      >
                        {{ totalRefundPages }}
                      </button>
                    </div>
                  </template>
                  <template v-else-if="refundPage > 4 && refundPage < totalRefundPages - 4">
                    <div class="col-auto">
                      <button
                        class="size-40 flex-center rounded-full"
                        @click="refundChangePage(1)"
                      >
                        1
                      </button>
                    </div>
                    <div class="col-auto">
                      <button
                        class="size-40 flex-center rounded-full"
                        @click="refundChangePage(2)"
                      >
                        2
                      </button>
                    </div>
                    <div class="col-auto">
                      <div class="size-40 flex-center rounded-full">...</div>
                    </div>
                    <div v-for="n in 4" class="col-auto">
                      <button
                        class="size-40 flex-center rounded-full"
                        v-bind:class="{ 'bg-dark-1 text-white': refundPage === refundPage + n - 2 }"
                        :disabled="refundPage === refundPage + n - 2"
                        @click="refundChangePage(refundPage + n - 2)"
                      >
                        {{ refundPage + n - 2 }}
                      </button>
                    </div>
                    <div class="col-auto">
                      <div class="size-40 flex-center rounded-full">...</div>
                    </div>
                    <div class="col-auto">
                      <button
                        class="size-40 flex-center rounded-full"
                        @click="refundChangePage(totalRefundPages - 1)"
                      >
                        {{ totalRefundPages - 1 }}
                      </button>
                    </div>
                    <div class="col-auto">
                      <button
                        class="size-40 flex-center rounded-full"
                        @click="refundChangePage(totalRefundPages)"
                      >
                        {{ totalRefundPages }}
                      </button>
                    </div>
                  </template>
                  <template v-else>
                    <div class="col-auto">
                      <button
                        class="size-40 flex-center rounded-full"
                        @click="refundChangePage(1)"
                      >
                        1
                      </button>
                    </div>
                    <div class="col-auto">
                      <button
                        class="size-40 flex-center rounded-full"
                        @click="refundChangePage(2)"
                      >
                        2
                      </button>
                    </div>
                    <div class="col-auto">
                      <div class="size-40 flex-center rounded-full">...</div>
                    </div>
                    <div v-for="n in 5" class="col-auto">
                      <button
                        class="size-40 flex-center rounded-full"
                        v-bind:class="{
                          'bg-dark-1 text-white': refundPage === totalRefundPages + n - 5,
                        }"
                        :disabled="refundPage === totalRefundPages + n - 5"
                        @click="refundChangePage(totalRefundPages + n - 5)"
                      >
                        {{ totalRefundPages + n - 5 }}
                      </button>
                    </div>
                  </template>
                </template>
              </div>

              <div class="text-center mt-30 md:mt-10">
                <div class="text-14 text-light-1">
                  {{ refundStart + 1 }} – {{ totalRefunds > refundEnd ? refundEnd : totalRefunds }} of
                  {{ totalRefunds }} properties found
                </div>
              </div>
            </div>

            <div class="col-auto md:order-2">
              <button
                class="button -blue-1 size-40 rounded-full border-light"
                :disabled="page == totalRefundPages"
                @click="refundNext()"
              >
                <i class="icon-chevron-right text-12"></i>
              </button>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="cancelBookingModal"
    aria-labelledby="cancelBookingModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="cancelBookingModalLabel">Cancel Booking</h5>
        </div>
        <div class="modal-body" v-html="modelDescription"></div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary button px-30 fw-400 text-14 h-40"
            data-bs-dismiss="modal"
          >
            No
          </button>
          <button
            type="button"
            class="button px-30 fw-400 text-14 h-40 -blue-1 bg-blue-1 text-white"
            @click="cancelBooking()"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade"
    id="reviewBookingModal"
    aria-labelledby="reviewBookingModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="reviewBookingModalLabel">Review Booking</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form method="POST" id="customer_review_form" @submit.prevent="reviewForm">
            <section>
              <div class="container">
                <div class="row">
                  <div class="col-xl-12 col-lg-12">
                    <div class="row">
                      <div class="col-auto">
                        <h3 class="text-22 fw-500">Leave a Reply</h3>
                        <p class="text-15 text-dark-1 mt-5">
                          Your email address will not be published.
                        </p>
                      </div>
                    </div>

                    <div class="row y-gap-30 pt-30">
                      <div class="col-xl-4 location-div">
                        <div class="text-15 lh-1 fw-500">Location</div>
                        <p>
                          <input class="location" name="location" value="0" />
                        </p>
                      </div>
                      <div class="col-xl-4 staff-div">
                        <div class="text-15 lh-1 fw-500">Staff</div>
                        <p>
                          <input class="staff" name="staff" value="0" />
                        </p>
                      </div>

                      <div class="col-xl-4 cleanliness-div">
                        <div class="text-15 lh-1 fw-500">Cleanliness</div>
                        <p>
                          <input class="cleanliness" name="cleanliness" value="0" />
                        </p>
                      </div>

                      <div class="col-xl-4 value-for-money-div">
                        <div class="text-15 lh-1 fw-500">Value for money</div>
                        <p>
                          <input
                            class="value_for_money"
                            name="value_for_money"
                            value="0"
                          />
                        </p>
                      </div>

                      <div class="col-xl-4 comfort-div">
                        <div class="text-15 lh-1 fw-500">Comfort</div>
                        <p>
                          <input class="comfort" name="comfort" value="0" />
                        </p>
                      </div>

                      <div class="col-xl-4 facilities-div">
                        <div class="text-15 lh-1 fw-500">Facilities</div>
                        <p>
                          <input class="facilities" name="facility" value="0" />
                        </p>
                      </div>

                      <div class="col-xl-4 free-wifi-div">
                        <div class="text-15 lh-1 fw-500">Free WiFi</div>
                        <p>
                          <input class="free_wifi" name="free_wifi" value="0" />
                        </p>
                      </div>
                    </div>

                    <div class="row y-gap-30 pt-20">
                      <input
                        type="hidden"
                        class="hotel_id"
                        id="hotel_id"
                        name="hotel_id"
                        v-model="hotelId"
                      />
                      <input
                        type="hidden"
                        class="booking_id"
                        id="booking_id"
                        name="booking_id"
                        v-model="bookingId"
                      />

                      <div class="col-xl-12">
                        <div class="form-input">
                          <input
                            type="text"
                            name="review_mail"
                            v-model="review_mail"
                            requiredas
                          />
                          <label class="lh-1 text-16 text-light-1">Email</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-input">
                          <textarea
                            required
                            rows="4"
                            name="review_comment"
                            v-model="review_comment"
                          ></textarea>
                          <label class="lh-1 text-16 text-light-1"
                            >Write Your Comment</label
                          >
                        </div>
                      </div>
                      <div class="col-12 relative">
                        Review Images:
                        <vue-multi-image-upload
                          @data-image="images"
                          :max="4"
                          :data-reset="component"
                          :options="options"
                          :image-size="imageSize"
                          :image-format="formatType"
                        />
                        <button @click="component.clear = true" id="clear_image">
                          <i class="icon-trash"> </i>
                        </button>
                      </div>

                      <div class="col-auto">
                        <div class="col-12 image-viewer-edit"></div>
                      </div>
                      <div class="col-auto" v-if="submitBtnView">
                        <button
                          class="button -md -dark-1 bg-blue-1 text-white"
                          type="submit"
                        >
                          Post Comment
                          <div class="icon-arrow-top-right ml-15"></div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import * as FileSaver from "file-saver";
import { defineComponent } from "vue";
import vue3starRatings from "vue3-star-ratings";
import { VueMultiImageUpload } from "@zakerxa/vue-multiple-image-upload";

export default {
  components: {
    vue3starRatings,
    VueMultiImageUpload,
  },
  modules: ["nuxt-rating"],
  props: {
    userId: Number,
    userEmail: String,
    notApprovedCustomerReviewsList: Array,
    approvedCustomerReviewsList: Array,
  },
  data: function () {
    return {
      UserId: Number,
      UserEmail: String,
      bookings: Array,
      refunds: Array,
      isLoading: false,
      defaultCurreny: "$",
      start: 0,
      end: 0,
      pageLength: 10,
      page: 1,
      totalPages: 1,

      refundStart: 0,
      refundEnd: 0,
      refundPage: 1,
      totalRefundPages: 1,

      totalBookings: 0,
      totalRefunds: 0,

      isToggled: false,
      modelDescription: "",
      selectedBooking: "",
      assetsPath: "",
      location: 3,
      staff: 3,
      cleanliness: 3,
      value_for_money: 3,
      comfort: 3,
      facility: 3,
      free_wifi: 3,
      hotelId: null,
      bookingId: null,
      review_mail: "",
      review_comment: "",
      component: {},
      options: {},
      imageSize: 5000000, // 5MB
      formatType: ["image/jpeg", "image/png", "image/jpg"],
      reviewImageStore: [],
      notApprovedCustomerReviews: [],
      approvedCustomerReviews: [],
      submitBtnView: true,
    };
  },
  computed: {},
  mounted: function () {
    this.defaultCurreny = window.defaultCurrency;
    // SCALTEN LOADING
    this.getBookingData();
    this.getRefundData();
    this.assetsPath = window.assetsPath;
    this.UserId = this.userId;
    this.UserEmail = this.userEmail;
    this.review_mail = this.UserEmail;
    this.options.max = "Max";
    this.options.ready = "Ready";
    this.options.select = "Choosed";
    this.notApprovedCustomerReviews = this.notApprovedCustomerReviewsList;
    this.approvedCustomerReviews = this.approvedCustomerReviewsList;
  },
  beforeMount() {},
  methods: {
    clear() {
      this.component.clear = true;
    },

    images(e) {
      let images = [];
      e.map((res) => images.push(res));
      this.reviewImageStore = images;
    },

    is_date_passed: function(date) {
      return moment().isAfter(moment(date));
    },

    writeReview: function (booking_id, hotel_id) {
      this.submitBtnView = true;
      this.booking_id = null;
      this.hotel_id = null;
      this.review_comment = "";

      this.clear();
      $(".fa-star").attr("class", "fa fa-star-o");
      $("#customer_review_form")[0].reset();
      this.review_mail = this.UserEmail;
      $(".image-viewer-edit").empty();
      this.reviewImageStore = [];

      $.ajaxSetup({
        headers: {
          "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
      });

      axios
        .post("/customer-review-edit", {
          userId: this.UserId,
          bookingId: booking_id,
          hotelId: hotel_id,
        })
        .then((response) => {
          if (response.data.data != null) {
            this.review_comment = response.data.data["review_comment"];
            this.location = response.data.data["location_ratings"];
            this.staff = response.data.data["staff_ratings"];
            this.cleanliness = response.data.data["cleanliness_ratings"];
            this.value_for_money = response.data.data["value_for_money_ratings"];
            this.comfort = response.data.data["comfort_ratings"];
            this.facility = response.data.data["facilities_ratings"];
            this.free_wifi = response.data.data["free_wifi_ratings"];

            for (let index = 1; index <= 5; index++) {
              if (index <= parseInt(response.data.data["location_ratings"])) {
                $(".location-div [data-rating=" + index + "]").click();
              }
              if (index <= parseInt(response.data.data["staff_ratings"])) {
                $(".staff-div [data-rating=" + index + "]").click();
              }
              if (index <= parseInt(response.data.data["cleanliness_ratings"])) {
                $(".cleanliness-div [data-rating=" + index + "]").click();
              }
              if (index <= parseInt(response.data.data["value_for_money_ratings"])) {
                $(".value-for-money-div [data-rating=" + index + "]").click();
              }
              if (index <= parseInt(response.data.data["comfort_ratings"])) {
                $(".comfort-div [data-rating=" + index + "]").click();
              }
              if (index <= parseInt(response.data.data["facilities_ratings"])) {
                $(".facilities-div [data-rating=" + index + "]").click();
              }
              if (index <= parseInt(response.data.data["free_wifi_ratings"])) {
                $(".free-wifi-div [data-rating=" + index + "]").click();
              }
            }

            var customer_review_images = response.data.data["customer_review_images"]
              ? JSON.parse(response.data.data["customer_review_images"])
              : [];
            var str = "";
            $.each(customer_review_images, (index, image) => {
              str += `<div class="col-4" id="image-container-${index}">
                <img src="${this.assetsPath}storage/customer_review/${image}" alt="Review Image">
                <a href="#" onclick="removeImage(${index}, '${image}','${this.userId}','${booking_id}','${hotel_id}')" style="margin-top: 5px; color:red;">Remove</a>
              </div>`;
            });

            $(".image-viewer-edit").html("<div class='row'>" + str + "</div>");
          }

          $("#reviewBookingModal").modal("show");
        })
        .catch((error) => {
          window.fnToast(error, "error");
        });

      this.bookingId = booking_id;
      this.hotelId = hotel_id;
    },

    approvedReviews: function (booking_id, hotel_id) {
      axios
        .post("/approved-customer-review", {
          userId: this.UserId,
          bookingId: booking_id,
          hotelId: hotel_id,
        })
        .then((response) => {
          if (response.data.data != null) {
            this.review_comment = "";
            this.review_comment = response.data.data["review_comment"];
            this.location = response.data.data["location_ratings"];
            this.staff = response.data.data["staff_ratings"];
            this.cleanliness = response.data.data["cleanliness_ratings"];
            this.value_for_money = response.data.data["value_for_money_ratings"];
            this.comfort = response.data.data["comfort_ratings"];
            this.facility = response.data.data["facilities_ratings"];
            this.free_wifi = response.data.data["free_wifi_ratings"];

            for (let index = 1; index <= 5; index++) {
              if (index <= parseInt(response.data.data["location_ratings"])) {
                $(".location-div [data-rating=" + index + "]").click();
              }
              if (index <= parseInt(response.data.data["staff_ratings"])) {
                $(".staff-div [data-rating=" + index + "]").click();
              }
              if (index <= parseInt(response.data.data["cleanliness_ratings"])) {
                $(".cleanliness-div [data-rating=" + index + "]").click();
              }
              if (index <= parseInt(response.data.data["value_for_money_ratings"])) {
                $(".value-for-money-div [data-rating=" + index + "]").click();
              }
              if (index <= parseInt(response.data.data["comfort_ratings"])) {
                $(".comfort-div [data-rating=" + index + "]").click();
              }
              if (index <= parseInt(response.data.data["facilities_ratings"])) {
                $(".facilities-div [data-rating=" + index + "]").click();
              }
              if (index <= parseInt(response.data.data["free_wifi_ratings"])) {
                $(".free-wifi-div [data-rating=" + index + "]").click();
              }
            }

            var customer_review_images = response.data.data["customer_review_images"]
              ? JSON.parse(response.data.data["customer_review_images"])
              : [];
            var str = "";
            $.each(customer_review_images, (index, image) => {
              str += `<div class="col-4" id="image-container-${index}">
                <img src="${this.assetsPath}storage/customer_review/${image}" alt="Review Image">
              </div>`;
            });

            $(".image-viewer-edit").html("<div class='row'>" + str + "</div>");
          }

          this.submitBtnView = false;
          $("#reviewBookingModal").modal("show");
        })
        .catch((error) => {
          window.fnToast(error, "error");
        });
    },

    reviewForm: function () {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      let formData = new FormData();
      for (let index = 0; index < this.reviewImageStore.length; index++) {
        formData.append("image[" + index + "]", this.reviewImageStore[index]);
      }

      let formData1 = $("#customer_review_form").serializeArray();
      formData.append("UserId", this.UserId);
      formData.append("formData1", JSON.stringify(formData1));

      axios
        .post("/customer-review-store", formData, config)
        .then((response) => {
          this.booking_id = null;
          this.hotel_id = null;
          this.review_mail = this.UserEmail;
          this.review_comment = "";
          this.reviewImageStore = [];
          this.clear();
          $(".fa-star").attr("class", "fa fa-star-o");
          $("#customer_review_form")[0].reset();
          if (response.data.status == true) {
            window.fnToast("thank you for response", "success");
          } else {
            window.fnToast(response.data.message, "error");
          }

          $("#reviewBookingModal").modal("hide");
        })
        .catch(function (error) {
          window.fnToast(error, "error");
        });
    },

    downloadInvoice: function (booking_id) {
      $(".preloader").css("opacity", "inherit");
      window.location.href = "/invoice/" + booking_id;
      setTimeout(() => {
        $(".preloader").css("opacity", 0);
      }, 5000);
    },

    getBookingData: function () {
      axios
        .post("/get-user-booking-details", {
          userId: this.userId,
          pageLength: this.pageLength,
          start: (this.page - 1) * this.pageLength,
          "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        })
        .then((response) => {
          if (response.data.status == true) {
            this.bookings = response.data.data;
            this.totalBookings = response.data.total;
            this.setPaginationData(true);
          }
        })
        .catch(function (error) {
          window.fnToast(error, "error");
        });
    },

    getRefundData: function () {
      axios
        .post("/get-user-refund-details", {
          userId: this.userId,
          pageLength: this.pageLength,
          start: (this.page - 1) * this.pageLength,
          "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        })
        .then((response) => {
          if (response.data.status == true) {
            this.refunds = response.data.data;
            this.totalRefunds = response.data.total;
            this.setRefundPaginationData(true);
          }
        })
        .catch(function (error) {
          window.fnToast(error, "error");
        });
    },

    prev: function () {
      this.page--;
      this.getBookingData();
    },

    next: function () {
      this.page++;
      this.getBookingData();
    },

    changePage: function (n) {
      this.page = n;
      this.getBookingData();
    },

    formateDate: function (date) {
      return moment(date).format("DD MMM YYYY");
    },

    setPaginationData: function (scroll = true) {
      this.start = this.pageLength * (this.page - 1);
      this.end = this.page * this.pageLength;

      this.totalPages = Math.ceil(this.totalBookings / this.pageLength);
      if (scroll) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },

    refundPrev: function () {
      this.refundPage--;
      this.getRefundData();
    },

    refundNext: function () {
      this.refundPage++;
      this.getRefundData();
    },

    refundChangePage: function (n) {
      this.refundPage = n;
      this.getRefundData();
    },

    setRefundPaginationData: function (scroll = true) {
      this.refundStart = this.pageLength * (this.refundPage - 1);
      this.refundEnd = this.refundPage * this.pageLength;

      this.totalRefundPages = Math.ceil(this.totalRefunds / this.pageLength);
      if (scroll) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },

    toggleDropdownMenu: function (element) {
      const target = $(".dropdown.js-dropdown.js-actions-active");
      target.each((index, el) => {
        $(el).removeClass("-is-dd-active");
        $(el).find(".toggle-element").removeClass("-is-el-visible");
      });

      element.classList.toggle("-is-dd-active");
      if ($(element).find(".toggle-element").hasClass("-is-el-visible")) {
        $(element).find(".toggle-element").removeClass("-is-el-visible");
      } else {
        $(element).find(".toggle-element").addClass("-is-el-visible");
      }
    },

    hotelDetail: function (booking) {
      const { id, hotel_id, city_code, hotel_name, city_name, country_code } = booking;
      const checkInDate = moment().add(7, "days").format("DD/MM/YYYY");
      const checkOutDate = moment().add(8, "days").format("DD/MM/YYYY");
      const roomUsers = [
        {
          adults: 2,
          children: 0,
          childrenAge: [],
        },
      ];
      $("#hotel_detail_" + id).append(
        `<input type="hidden" name="cityId" value="${city_code}" />
        <input type="hidden" name="hotelCityName" value="${hotel_name}" />
        <input type="hidden" name="cityName" value="${city_name}" />
        <input type="hidden" name="countryCode" value="${country_code}" />
        <input type="hidden" name="checkInDate" value="${checkInDate}" />
        <input type="hidden" name="checkOutDate" value="${checkOutDate}" />
        <input type="hidden" name="guestNationalityCountryCode" value="${booking.guest_nationality}" />
        <input type="hidden" name="roomUsers" value='${JSON.stringify(roomUsers)}' />`
      );
      $("#hotel_detail_" + id).submit();
    },

    cancelBookingWarning: function (booking) {
      this.selectedBooking = booking.id;
      axios
        .post("/get-cancellation-policy", {
          id: booking.id,
          "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        })
        .then((response) => {
          if (response.data.status == true) {
            this.modelDescription = response.data.data;
            $("#cancelBookingModal").modal("show");
          } else {
            window.fnToast(response.data.message, "error");
          }
        })
        .catch(function (error) {
          window.fnToast(error, "error");
        });
    },

    cancelBooking: function (booking) {
      $(".preloader").css("opacity", "inherit");
      axios
        .post("/cancel-booking", {
          id: this.selectedBooking,
          "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        })
        .then((response) => {
          $(".preloader").css("opacity", 0);
          if (response.data.status == true) {
            window.fnToast(
              "Your Booking is cancellation requested successfully. Once the Admin approve it, you will get the refund..",
              "success"
            );
            this.getBookingData();
            $("#cancelBookingModal").modal("hide");
          } else {
            $("#cancelBookingModal").modal("hide");
            window.fnToast(response.data.message, "error");
          }
        })
        .catch(function (error) {
          $(".preloader").css("opacity", 0);
          window.fnToast(error, "error");
        });
    },
  },
};
</script>
