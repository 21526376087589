<template>
  <div class="tabs__content pt-30 js-tabs-content">
    <div class="tabs__pane -tab-item-1 is-tab-el-active">
      <div class="row y-gap-20">
        <div v-for="hotel in hotels" v-if="hotels">
          <div class="col-12">
            <div class="">
              <div class="row x-gap-20 y-gap-30">
                <div class="col-md-auto">
                  <div class="cardImage ratio ratio-1:1 w-200 md:w-1/1 rounded-4">
                    <div class="cardImage__content">
                      <img
                        class="rounded-4 col-12"
                        :src="

                          Object.keys(hotel.MainImage).length != 0
                            ? hotel.MainImage
                            : 'http://test.xmlhub.com/images/noimage.gif'
                        "
                        alt="uploaded-image"
                      />
                    </div>

                    <div class="cardImage__wishlist">
                      <button
                        class="button -blue-1 bg-white size-30 rounded-full shadow-2"
                        @click="
                          wishList(
                            hotel.HotelId,
                            allWishlistHotelsList.includes(parseInt(hotel.HotelId))
                          )
                        "
                        v-bind:class="{
                          'w-selected': allWishlistHotelsList.includes(
                            parseInt(hotel.HotelId)
                          ),
                        }"
                      >
                        <i class="icon-heart text-12"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md">
                  <h3 class="text-18 lh-14 fw-500">{{ hotel.HotelName }}</h3>

                  <div class="d-flex x-gap-5 items-center pt-10">
                    <template v-for="index in parseInt(hotel.Rating)">
                      <i
                        class="icon-star text-10 text-yellow-1"
                        v-bind:class="{'ml-4': index > 0}"
                      ></i>
                    </template>
                  </div>

                  <div class="row x-gap-10 y-gap-10 items-center pt-20">
                    <div class="col-auto">
                      <p class="text-14">
                        {{ hotel.HotelAddress }} {{ hotel.Country }}
                        <!-- <button data-x-click="mapFilter" class="text-blue-1 underline ml-10">Show on map</button> -->
                      </p>
                    </div>

                    <!-- <div class="col-auto">
                      <div class="size-3 rounded-full bg-light-1"></div>
                    </div> -->

                    <!-- <div class="col-auto">
                      <p class="text-14">2 km to city center</p>
                    </div> -->
                  </div>

                  <div class="row x-gap-10 y-gap-10 pt-20">
                    <div class="col-auto" v-for="tag in splitJoin(hotel.HotelAmenities)">
                      <div class="border-light rounded-100 py-5 px-20 text-14 lh-14">
                        {{ tag }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-auto text-right md:text-left">
                  <div class="d-flex flex-column justify-between h-full">
                    <!-- <div
                      class="row x-gap-10 y-gap-10 justify-end items-center md:justify-start"
                    >
                      <div class="col-auto">
                        <div class="text-14 lh-14 fw-500">Exceptional</div>
                        <div class="text-14 lh-14 text-light-1">3,014 reviews</div>
                      </div>
                      <div class="col-auto">
                        <div
                          class="flex-center text-white fw-600 text-14 size-40 rounded-4 bg-blue-1"
                        >
                          {{ hotel.Rating }}
                        </div>
                      </div>
                    </div>

                    <div class="pt-24">
                      <div class="fw-500">Starting from</div>
                      <span class="fw-500 text-blue-1">US$72</span> / night
                    </div> -->
                    <form
                      method="post"
                      v-bind:id="'hotel_detail_' + hotel.HotelId"
                      v-bind:action="'/hotel-detail/' + hotel.HotelId"
                    >
                      <button
                        type="button"
                        class="button -md -dark-1 bg-blue-1 text-white mt-24"
                        @click="hotelDetail(hotel)"
                      >
                        See Availability
                        <div class="icon-arrow-top-right ml-15"></div>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="totalPages > 0" class="border-top-light mt-30 pt-30">
          <div class="row x-gap-10 y-gap-20 justify-between md:justify-center">
            <div class="col-auto md:order-1">
              <button
                class="button -blue-1 size-40 rounded-full border-light"
                :disabled="page == 1"
                @click="prev()"
              >
                <i class="icon-chevron-left text-12"></i>
              </button>
            </div>

            <div class="col-md-auto md:order-3">
              <div class="row x-gap-20 y-gap-20 items-center">
                <template v-if="totalPages <= 5">
                  <div v-for="n in totalPages" class="col-auto">
                    <button
                      class="size-40 flex-center rounded-full"
                      v-bind:class="{ 'bg-dark-1 text-white': page === n }"
                      :disabled="page === n"
                      @click="changePage(n)"
                    >
                      {{ n }}
                    </button>
                  </div>
                </template>
                <template v-if="totalPages > 5">
                  <template v-if="page <= 4">
                    <div v-for="n in 5" class="col-auto">
                      <button
                        class="size-40 flex-center rounded-full"
                        v-bind:class="{ 'bg-dark-1 text-white': page === n }"
                        :disabled="page === n"
                        @click="changePage(n)"
                      >
                        {{ n }}
                      </button>
                    </div>
                    <div class="col-auto">
                      <div class="size-40 flex-center rounded-full">...</div>
                    </div>
                    <div class="col-auto">
                      <button
                        class="size-40 flex-center rounded-full"
                        @click="changePage(totalPages - 1)"
                      >
                        {{ totalPages - 1 }}
                      </button>
                    </div>
                    <div class="col-auto">
                      <button
                        class="size-40 flex-center rounded-full"
                        @click="changePage(totalPages)"
                      >
                        {{ totalPages }}
                      </button>
                    </div>
                  </template>
                  <template v-else-if="page > 4 && page < totalPages - 4">
                    <div class="col-auto">
                      <button
                        class="size-40 flex-center rounded-full"
                        @click="changePage(1)"
                      >
                        1
                      </button>
                    </div>
                    <div class="col-auto">
                      <button
                        class="size-40 flex-center rounded-full"
                        @click="changePage(2)"
                      >
                        2
                      </button>
                    </div>
                    <div class="col-auto">
                      <div class="size-40 flex-center rounded-full">...</div>
                    </div>
                    <div v-for="n in 4" class="col-auto">
                      <button
                        class="size-40 flex-center rounded-full"
                        v-bind:class="{ 'bg-dark-1 text-white': page === page + n - 2 }"
                        :disabled="page === page + n - 2"
                        @click="changePage(page + n - 2)"
                      >
                        {{ page + n - 2 }}
                      </button>
                    </div>
                    <div class="col-auto">
                      <div class="size-40 flex-center rounded-full">...</div>
                    </div>
                    <div class="col-auto">
                      <button
                        class="size-40 flex-center rounded-full"
                        @click="changePage(totalPages - 1)"
                      >
                        {{ totalPages - 1 }}
                      </button>
                    </div>
                    <div class="col-auto">
                      <button
                        class="size-40 flex-center rounded-full"
                        @click="changePage(totalPages)"
                      >
                        {{ totalPages }}
                      </button>
                    </div>
                  </template>
                  <template v-else>
                    <div class="col-auto">
                      <button
                        class="size-40 flex-center rounded-full"
                        @click="changePage(1)"
                      >
                        1
                      </button>
                    </div>
                    <div class="col-auto">
                      <button
                        class="size-40 flex-center rounded-full"
                        @click="changePage(2)"
                      >
                        2
                      </button>
                    </div>
                    <div class="col-auto">
                      <div class="size-40 flex-center rounded-full">...</div>
                    </div>
                    <div v-for="n in 5" class="col-auto">
                      <button
                        class="size-40 flex-center rounded-full"
                        v-bind:class="{
                          'bg-dark-1 text-white': page === totalPages + n - 5,
                        }"
                        :disabled="page === totalPages + n - 5"
                        @click="changePage(totalPages + n - 5)"
                      >
                        {{ totalPages + n - 5 }}
                      </button>
                    </div>
                  </template>
                </template>
              </div>

              <div class="text-center mt-30 md:mt-10">
                <div class="text-14 text-light-1">
                  {{ start + 1 }} – {{ totalResult > end ? end : totalResult }} of
                  {{ totalResult }} properties found
                </div>
              </div>
            </div>

            <div class="col-auto md:order-2">
              <button
                class="button -blue-1 size-40 rounded-full border-light"
                :disabled="page == totalPages"
                @click="next()"
              >
                <i class="icon-chevron-right text-12"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    totalHotels: Number,
    cityName: String,
    allHotels: Array,
    userId: Number,
    allWishlistHotels: Array,
  },
  data: function () {
    return {
      roomUsers: [
        {
          adults: 2,
          children: 0,
          childrenAge: [],
        },
      ],
      checkInDate: moment().add(7, "days").format("DD/MM/YYYY"),
      checkOutDate: moment().add(8, "days").format("DD/MM/YYYY"),
      assetsPath: "",
      defaultCurrency: "₹",
      start: 0,
      end: 0,
      pageLength: 15,
      page: 1,
      totalPages: 1,
      hotels: [],
      totalResult: 0,
      allWishlistHotelsList: [],
    };
  },
  computed: {},
  mounted: function () {
    this.allWishlistHotelsList = this.allWishlistHotels;
    this.UserId = this.userId;
    this.assetsPath = window.assetsPath;
    this.defaultCurrency = window.defaultCurrency;
    this.page = 1;
    this.setPaginationData();
  },

  methods: {
    splitJoin(theText) {
      let tags = [];
      let str = "";
      for (let index = 0; index < theText.length; index++) {
        if (theText[index] == ",") {
          tags.push(str);
          str = "";
        } else {
          str += theText[index];
        }
      }
      let tag = [];

      for (let index = 0; index < tags.length; index++) {
        if (index == 5) {
          break;
        }
        tag.push(tags[index]);
      }
      return tag;
    },

    prev: function () {
      this.page--;
      this.setPaginationData();
    },

    next: function () {
      this.page++;
      this.setPaginationData();
    },

    changePage: function (n) {
      this.page = n;
      this.setPaginationData();
    },

    setPaginationData: function (scroll = true) {
      this.start = this.pageLength * (this.page - 1);
      this.end = this.page * this.pageLength;

      this.totalPages = Math.ceil(this.allHotels.length / this.pageLength);
      this.totalResult = this.allHotels.length;
      this.hotels = this.allHotels.length > 1 ? this.allHotels.filter((h, i) => i >= this.start && i < this.end) : [this.allHotels] ;

      if (scroll) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },

    hotelDetail: function (hotel) {
      if (hotel.HotelId) {
        $("#hotel_detail_" + hotel.HotelId).append(
          `<input type="hidden" name="cityId" value="${hotel.CityCode}" />`
        );
        $("#hotel_detail_" + hotel.HotelId).append(
          `<input type="hidden" name="hotelCityName" value="${hotel.HotelName}" />`
        );
        $("#hotel_detail_" + hotel.HotelId).append(
          `<input type="hidden" name="cityName" value="${hotel.City}" />`
        );
        $("#hotel_detail_" + hotel.HotelId).append(
          `<input type="hidden" name="countryCode" value="${hotel.CountryCode}" />`
        );
        $("#hotel_detail_" + hotel.HotelId).append(
          `<input type="hidden" name="checkInDate" value="${this.checkInDate}" />`
        );
        $("#hotel_detail_" + hotel.HotelId).append(
          `<input type="hidden" name="checkOutDate" value="${this.checkOutDate}" />`
        );
        $("#hotel_detail_" + hotel.HotelId).append(
          `<input type="hidden" name="roomUsers" value='${JSON.stringify(
            this.roomUsers
          )}' />`
        );
        $("#hotel_detail_" + hotel.HotelId).submit();
      }
    },
    wishList: function (hotelId, isWishlistHotel) {
      if (!this.userId) {
        window.location.href = "/login";
      } else {
        axios
          .post("/wishlist", {
            hotelId: hotelId,
            userId: this.userId,
          })
          .then((response) => {
            if (isWishlistHotel) {
              const index = this.allWishlistHotelsList.findIndex((hId) => hId == hotelId);
              this.allWishlistHotelsList.splice(index, 1);
            } else {
              this.allWishlistHotelsList.push(parseInt(hotelId));
            }
            window.fnToast(response.data.message, "success");
          })
          .catch(function (error) {
            window.fnToast(error, "error");
          });
      }
    },
  },
};
</script>
