<template>
  <div class="singleMenu">
    <div class="col-12">
      <div class="singleMenu__content">
        <div class="container">
          <div class="row y-gap-20 justify-between items-center">
            <div class="col-auto">
              <div class="singleMenu__links row x-gap-30 y-gap-10">
                <div class="col-auto">
                  <a href="#overview">Overview</a>
                </div>
                <div class="col-auto">
                  <a href="#rooms">Rooms</a>
                </div>
                <!-- <div class="col-auto">
                  <a href="#reviews">Reviews</a>
                </div> -->
                <div class="col-auto">
                  <a href="#facilities">Facilities</a>
                </div>
                <!-- <div class="col-auto">
                  <a href="#faq">Faq</a>
                </div> -->
              </div>
            </div>
            <div class="col-auto">
              <div class="row x-gap-15 y-gap-15 items-center">
                <div class="col-auto">
                  <div class="text-14">
                    From
                    <span v-if="!isLoading" class="text-22 text-dark-1 fw-500">
                      {{ defaultCurrency }} {{ minRoomPrice }}
                    </span>
                  </div>
                </div>

                <div class="col-auto">
                  <a href="#rooms" class="button h-50 px-24 -dark-1 bg-blue-1 text-white">
                    Select Room
                    <div class="icon-arrow-top-right ml-15"></div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section class="pt-40 pb-40"  v-show="modifySearch" style="background: linear-gradient(276deg, #EA4D46 0%, #FFC824 100%);">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <h1 class="text-30 fw-600 listing-page-text">Book Hotels & Homestays</h1>
          </div>
          <div class="mainSearch -col-3-big bg-white px-10 py-10 lg:px-20 lg:pt-5 lg:pb-20 rounded-4 mt-30"
            style="border-radius: 50px;">
            <hotel-search-component  :search-hotel-id="searchHotelId" :search-city-id="searchCityId"
              :guest-nationality="guestNationality"
              :guest-nationality-country-code="guestNationalityCountryCode"
              :search-hotel-city-name="searchHotelCityName" :search-city-name="searchCityName"
              :search-country-code="searchCountryCode" :search-check-in-date="searchCheckInDate"
              :search-check-out-date="searchCheckOutDate" :search-room-users="searchRoomUsers" :path="path"
              :current-url="currentUrl" :default-date="Boolean(defaultDate)" :client-ip="clientIp">
            </hotel-search-component>
            
          </div>
        </div>
       
      </div>
    </div>
  </section>

  <section class="pt-5 pb-5" v-show="!modifySearch" style="background: linear-gradient(276deg, #EA4D46 0%, #FFC824 100%);">
    <div class="container">
      <div class="row">
        <div class="col-12" v-on:click="modifySearch=true">
          <div class="text-center text-white">
            <i class="icon-search text-15 text-white" ></i>
            Modify Search
          </div>
          
        </div>
       
      </div>
    </div>
  </section>


  <section class="py-10 d-flex items-center bg-light-2">
    <div class="container">
      <div class="row y-gap-10 items-center justify-between">
        <div class="col-auto">
          <div class="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
            <div class="col-auto">
              <a :href="homePagePath">Home</a>
            </div>
            <div class="col-auto">
              <div class=""></div>
            </div>
            <div class="col-auto">
              <div v-if="hotelContent" class="text-dark-1">
                {{ hotelContent["name"] }}, {{ hotelContent["city_name"] }},
                {{ hotelContent["country"] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="pt-40">
    <div class="container">
      <div>
        <span v-if="hotelContent">
          <div v-if="windowWidth>570" class="galleryGrid -type-1 pt-30">
            <div class="galleryGrid__item relative d-flex">
              <img :src="hotelContent['mainImage'] == 'http://test.xmlhub.com/images/noimage.gif' ? assetsPath + 'front-assets/img/general/image_not_found-01.jpg'
                : hotelContent['mainImage']" alt="image" class="rounded-4 img-responsive "
                @error="setDefaultImage" />
              <div class="absolute px-20 py-20 col-12 d-flex justify-end">
                <button class="button -blue-1 size-40 rounded-full flex-center bg-white text-dark-1"
                  @click="wishList(isHotelWishlist == searchHotelId)" v-bind:class="{
                    'w-selected': isHotelWishlist == searchHotelId,
                  }">
                  <i class="icon-heart text-16"></i>
                </button>
              </div>
            </div>

            <div class="galleryGrid__item" v-if="hotelImages[0]">
              <img :src="hotelImages[0]" alt="image" class="rounded-4" @error="setDefaultImage" />
            </div>

            <div class="galleryGrid__item relative d-flex" v-if="hotelImages[1]">
              <img :src="hotelImages[1]" alt="image" class="rounded-4" @error="setDefaultImage" />
            </div>

            <div class="galleryGrid__item" v-if="hotelImages[2]">
              <img :src="hotelImages[2]" alt="image" class="rounded-4" @error="setDefaultImage" />
            </div>

            <div class="galleryGrid__item relative d-flex" v-if="hotelImages[3]">
              <img :src="hotelImages[3]" alt="image" class="rounded-4" @error="setDefaultImage" />

              <div class="absolute px-10 py-10 col-12 h-full d-flex justify-end items-end">
                <a :href="hotelContent['mainImage'] == 'http://test.xmlhub.com/images/noimage.gif' ? assetsPath + 'front-assets/img/general/image_not_found-01.jpg'
                  : hotelContent['mainImage']" class="button -blue-1 px-24 py-15 bg-white text-dark-1 js-gallery"
                  data-gallery="gallery2">
                  See All Photos
                </a>

                <a v-for="hotelImage in hotelImages" :href="hotelImage == 'http://test.xmlhub.com/images/noimage.gif' ? assetsPath + 'front-assets/img/general/image_not_found-01.jpg'
                  : hotelImage" class="js-gallery" data-gallery="gallery2"></a>
              </div>
            </div>
          </div>
          <div v-if="windowWidth<=570">
              <div class="row d-flex">
                <div class="col-12" style="position: relative;">
                  
                    <img data-gallery="gallery2" :src="hotelContent['mainImage'] == 'http://test.xmlhub.com/images/noimage.gif' ? assetsPath + 'front-assets/img/general/image_not_found-01.jpg'
                : hotelContent['mainImage']" alt="" class="rounded-4 img-responsive js-gallery"
                @error="setDefaultImage" />
                <a :href="hotelContent['mainImage'] == 'http://test.xmlhub.com/images/noimage.gif' ? assetsPath + 'front-assets/img/general/image_not_found-01.jpg'
                : hotelContent['mainImage']" class="js-gallery" data-gallery="gallery2" style="background-color: #ffffffb5;
                border-radius: 5px;
                padding: 5px 10px;
                position: absolute;
                bottom: 12px;
                right: 26px;">See All Photos</a>
                </div>
              </div>
              <div class="d-flex pt-10">
                <div v-for="(hotelImage, index) in hotelImages" :style="{'max-height':(windowWidth)/3-20+'px','flex':index>2?'none':'1', 'flex-shrink':index>2?'1':'0', 'display':index>2?'none':'block' ,'padding-right':  index<2? '5px':'0px'}">
                    <img :src="hotelImage" data-gallery="gallery2" style="height:100%; object-fit:cover" alt="" class="rounded-4 img-responsive js-gallery" @error="setDefaultImage" />
                </div>
               
              </div>
          </div>
        </span>

        <span class="row" v-else>
          <div class="galleryGrid -type-1 pt-30" style="height: 500px">
            <div class="galleryGrid__item relative d-flex skeleton h-200px">
              <div class="absolute px-20 py-20 col-12 d-flex justify-end">
                <button class="button -blue-1 size-40 rounded-full flex-center bg-white text-dark-1">
                  <i class="icon-heart text-16"></i>
                </button>
              </div>
            </div>

            <div class="galleryGrid__item skeleton h-200px">
            </div>

            <div class="galleryGrid__item relative d-flex skeleton h-200px">
              <div class="absolute h-full col-12 flex-center skeleton h-200px">
                <a href="https://www.youtube.com/watch?v=ANYfx4-jyqY"
                  class="button -blue-1 size-40 rounded-full flex-center bg-white text-dark-1 js-gallery"
                  data-gallery="gallery1">
                  <i class="icon-play text-16"></i>
                </a>
              </div>
            </div>

            <div class="galleryGrid__item skeleton h-200px">

            </div>

            <div class="galleryGrid__item relative d-flex skeleton h-200px">

              <div class="absolute px-10 py-10 col-12 h-full d-flex justify-end items-end">
                <a href="img/gallery/1/1.png" class="button -blue-1 px-24 py-15 bg-white text-dark-1 js-gallery"
                  data-gallery="gallery2">
                  See All Photos
                </a>
                <a href="img/gallery/1/2.png" class="js-gallery" data-gallery="gallery2"></a>
                <a href="img/gallery/1/3.png" class="js-gallery" data-gallery="gallery2"></a>
                <a href="img/gallery/1/4.png" class="js-gallery" data-gallery="gallery2"></a>
                <a href="img/gallery/1/5.png" class="js-gallery" data-gallery="gallery2"></a>
              </div>
            </div>
          </div>
        </span>

        <div class="row justify-between items-start pt-40 sm:pt-10">
          <div class="col-auto">
            <div class="row x-gap-20 y-gap-20 items-center">
              <div class="col-auto">
                <span v-if="hotelContent">
                  <h1 class="text-26 fw-600 sm:text-20">{{ hotelContent["name"] }}</h1>
                </span>
                <span v-else>
                  <div class="skeleton skeleton-text-1"></div>
                </span>
              </div>

              <div v-if="hotelContent" class="col-auto">
                <template v-for="index in parseInt(hotelContent['rating'])">
                  <i class="icon-star text-10 text-yellow-2" v-bind:class="{ 'ml-4': index > 0 }"></i>
                </template>
              </div>
            </div>

            <div class="row x-gap-20 y-gap-20 sm:x-gap-0 sm:y-gap-5 items-center">
              <div class="col-auto">
                <div v-if="hotelContent" class="text-15 sm:text-14 text-light-1">
                  {{ hotelContent["address"] }}
                  {{ hotelContent["country_name"] }}
                </div>
                <div v-else>
                  <div class="skeleton skeleton-text-2"></div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="col-auto d-none md:d-block" v-if="this.hotelRoomDetailsError != null">
            <div class="notAvailableRooms">
              <h6>You Just Missed It</h6>
              <p>Not available for selected dates on MyVagaTrip</p>
            </div>
          </div>
          <div class="col-auto sm:d-none md:d-block" v-else>
            <div class="text-14 text-right">
              <div v-if="!isLoading" class="text-14 lh-14 text-light-1 mb-5">
                {{ totalRooms }} room{{ totalRooms > 1 ? 's' : '' }},
                {{ totalNights }} night{{ totalNights > 1 ? 's' : '' }}
              </div>
              <span v-if="!isLoading">From </span>
              <span v-if="!isLoading" class="text-22 text-dark-1 fw-500">
                {{ defaultCurrency }} {{ displayPropertyRate(minRoomPrice) }}
                <h6 class="text-14 text-light-1">(inc. GST)</h6>
              </span>
              <span v-else class="text-22 text-dark-1 fw-500">
                <div class="skeleton skeleton-text-3"></div>
              </span>
            </div>
            <a v-if="!isLoading" href="#rooms" class="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-5">
              Select Room
              <div class="icon-arrow-top-right ml-15"></div>
            </a>
          </div>
        </div>

        <div id="overview" class="row y-gap-40 sm:y-gap-10 pt-40 sm:pt-5" >
          <div class="col-12">
            <div v-if="hotelContent && hotelContent['overview'] && hotelContent['overview'].length > 0">
              <h3 class="text-22 fw-500 pt-40 border-top-light">Overview</h3>
              <template v-if="typeof hotelContent['overview'] == 'string'">
                <p class="text-dark-1 text-15 mt-20" v-html="hotelContent['overview']"></p>
              </template>
            </div>
            <div v-if="hotelContent"></div>
            <div v-else>
              <div class="skeleton skeleton-text-1"></div>
            </div>
          </div>

          <div v-if="hotelContent" :style="{'position':'relative','max-height':isOverviewVisible?'100%':'250px','overflow':isOverviewVisible?'none':'hidden'}">
            <div class="col-12">
              <h3 class="text-22 fw-500 pt-40 sm:pt-10 sm:text-16 border-top-light">
                Most Popular Facilities
              </h3>
              <div class="row y-gap-10 pt-20">
                <div class="col-6 col-md-4" v-for="index in hotelContent['Amenities']">
                  <div class="d-flex x-gap-15 y-gap-15 items-center">
                    <i class="icon-check text-10 mr-20"></i>
                    <div class="text-15 sm:text-12">{{ index }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!isOverviewVisible" v-on:click="isOverviewVisible=true" style="position: absolute; bottom:0px; left:0px; right:0px; padding:20px;margin-left:15px; margin-right:15px; border-radius:5px; background: rgb(0,0,0);
            background: linear-gradient(0deg, rgb(238 105 62 / 41%) 16%, rgba(255, 255, 255, 0) 100%); text-align:center; color:black; cursor:pointer">
              View More
            </div>
            
          </div>
          <div v-else>
            <div class="text-18 lh-16 fw-500 skeleton skeleton-text-4 skeleton-body-75"></div>
          </div>

        

        </div>
      </div>
    </div>
  </section>

  <div v-if="hotelContent && hotelRoomList.length > 0">
    <room-list-component :search-hotel-id="hotelContent['property_id']" :search-session-id="searchSessionId"
        :guest-nationality="guestNationality"
        :guest-nationality-country-code="guestNationalityCountryCode"
      :all-hotel-rooms="hotelRoomList" :inputs="inputs" :hotelContent="hotelContent">
    </room-list-component>
  </div>

  <div v-if="!isLoading" class="text-dark-1 text-15 mt-20"></div>
  <div v-else class="container mt-20">
    <div class="col-md">
      <div class="text-18 lh-16 fw-500 skeleton skeleton-text-4 skeleton-body-25"></div>
      <div class="text-18 lh-16 fw-500 skeleton skeleton-text-4 skeleton-body-75"></div>
    </div>
  </div>


  <div v-if="!isLoading">
    <div id="facilities"></div>
    <section class="mt-40">
      <div class="container">
        <div class="row x-gap-40 y-gap-40">
          <div class="col-md-6">
            <div class="row x-gap-40 y-gap-40 pt-20">
              <!-- <div class="col-xl-12">
                <div class="row y-gap-30">
                  <div class="col-12">
                    <div class="">
                      <div class="d-flex items-center text-16 fw-500">
                        <i class="text-20 mr-10"></i>
                        Hotel Facilities
                      </div>

                      <div v-if="hotelContent && hotelContent['Amenities'].length > 0">
                        <ul class="text-15 pt-10">
                          <li class="d-flex items-center" v-for="index in hotelContent['Amenities']">
                            <i class="icon-check text-10 mr-20"></i>
                            {{ index }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  -->

              <div class="col-xl-12">
                <div class="row y-gap-30">
                  <div class="col-12">
                    <div class="">
                      <div class="d-flex items-center text-16 fw-500">
                        <i class="text-20 mr-10"></i>
                        Room Facilities
                      </div>
                      <ul class="row text-15 pt-10" v-if="roomAmenities">
                        <li class="col-4 d-flex items-center" v-for="index in roomAmenities">
                          <i class="icon-check text-10 mr-20"></i>
                          {{ index }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row x-gap-40 y-gap-40 pt-20">
              <div class="ratio ratio-16:9">
                <div class="map-ratio mapFilter-map">
                  <div class="map js-map"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="container mt-40 mb-40">
    <div class="border-top-light"></div>
  </div>

  <div id="reviews"></div>
  <section class="pt-40" v-if="!isLoading && guestReviews && totalReviews > 0">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h3 class="text-22 fw-500">Guest reviews</h3>
        </div>
      </div>

      <div class="row y-gap-30 items-center pt-20">
        <div class="col-lg-3">
          <div class="flex-center rounded-4 min-h-250 bg-blue-1-05">
            <div class="text-center">
              <div class="text-60 md:text-50 fw-600 text-blue-1">{{ ((totalReviewsCount * 10) / 5).toFixed(1) }}
              </div>
              <div class="fw-500 lh-1">Exceptional</div>
              <div class="text-14 text-light-1 lh-1 mt-5">{{ totalReviews }} reviews</div>
            </div>
          </div>
        </div>

        <div class="col-lg-9">
          <div class="row y-gap-30">
            <div class="col-md-4 col-sm-6">
              <div class="">
                <div class="d-flex items-center justify-between">
                  <div class="text-15 fw-500">Location</div>
                  <div class="text-15 text-light-1">{{ ((totalLocationRatings * 10) / 5).toFixed(1) }}</div>
                </div>

                <div class="progressBar mt-10">
                  <div class="progressBar__bg bg-blue-2"></div>
                  <div class="progressBar__bar bg-dark-1"
                    v-bind:style="{ width: ((totalLocationRatings * 100) / 5) + '%' }"></div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6">
              <div class="">
                <div class="d-flex items-center justify-between">
                  <div class="text-15 fw-500">Staff</div>
                  <div class="text-15 text-light-1">{{ ((totalStaffRatings * 10) / 5).toFixed(1) }}</div>
                </div>

                <div class="progressBar mt-10">
                  <div class="progressBar__bg bg-blue-2"></div>
                  <div class="progressBar__bar bg-dark-1" v-bind:style="{ width: ((totalStaffRatings * 100) / 5) + '%' }">
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6">
              <div class="">
                <div class="d-flex items-center justify-between">
                  <div class="text-15 fw-500">Cleanliness</div>
                  <div class="text-15 text-light-1">{{ ((totalCleanlinessRatings * 10) / 5).toFixed(1) }}</div>
                </div>

                <div class="progressBar mt-10">
                  <div class="progressBar__bg bg-blue-2"></div>
                  <div class="progressBar__bar bg-dark-1"
                    v-bind:style="{ width: ((totalCleanlinessRatings * 100) / 5) + '%' }"></div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6">
              <div class="">
                <div class="d-flex items-center justify-between">
                  <div class="text-15 fw-500">Value for money</div>
                  <div class="text-15 text-light-1">{{ ((totalValueForMoneyRatings * 10) / 5).toFixed(1) }}</div>
                </div>

                <div class="progressBar mt-10">
                  <div class="progressBar__bg bg-blue-2"></div>
                  <div class="progressBar__bar bg-dark-1"
                    v-bind:style="{ width: ((totalValueForMoneyRatings * 100) / 5) + '%' }"></div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6">
              <div class="">
                <div class="d-flex items-center justify-between">
                  <div class="text-15 fw-500">Comfort</div>
                  <div class="text-15 text-light-1">{{ ((totalComfortRatings * 10) / 5).toFixed(1) }}</div>
                </div>

                <div class="progressBar mt-10">
                  <div class="progressBar__bar bg-dark-1"
                    v-bind:style="{ width: ((totalComfortRatings * 100) / 5) + '%' }"></div>
                  <div class="progressBar__bg bg-blue-2"></div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6">
              <div class="">
                <div class="d-flex items-center justify-between">
                  <div class="text-15 fw-500">Facilities</div>
                  <div class="text-15 text-light-1">{{ ((totalFacilitesRatings * 10) / 5).toFixed(1) }}</div>
                </div>

                <div class="progressBar mt-10">
                  <div class="progressBar__bg bg-blue-2"></div>
                  <div class="progressBar__bar bg-dark-1"
                    v-bind:style="{ width: ((totalFacilitesRatings * 100) / 5) + '%' }"></div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6">
              <div class="">
                <div class="d-flex items-center justify-between">
                  <div class="text-15 fw-500">Free WiFi</div>
                  <div class="text-15 text-light-1">{{ ((totalFreeWifiRatings * 10) / 5).toFixed(1) }}</div>
                </div>

                <div class="progressBar mt-10">
                  <div class="progressBar__bg bg-blue-2"></div>
                  <div class="progressBar__bar bg-dark-1"
                    v-bind:style="{ width: ((totalFreeWifiRatings * 100) / 5) + '%' }"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="pt-40" v-if="totalReviews > 0">
    <div class="container">
      <div class="row y-gap-60">
        <div class="col-lg-6" v-for="(guestReview, index) in guestReviews">
          <div class="row x-gap-20 y-gap-20 items-center">
            <div class="col-auto">
              <span class="avatar" v-if="guestReview['profile_pic']">
                <img :src="assetsPath + 'storage/image/users/profile_pic/' + guestReview['profile_pic']" alt="Avatar" />
              </span>
              <span class="avatar" v-else>{{ Array.from(guestReview['first_name'])[0] }}</span>
            </div>
            <div class="col-auto">
              <div class="fw-500 lh-15">{{ guestReview['first_name'] + " " + guestReview['last_name'] }}</div>
              <div class="text-14 text-light-1 lh-15">{{ formateDate(guestReview['created_at']) }}</div>
            </div>
          </div>

          <div class="d-flex x-gap-5 items-center pt-10">
            <div class="icon-star text-10 text-yellow-1" v-for="rating in guestReview['total_ratings']"></div>
          </div>

          <p class="text-15 text-dark-1 mt-10">{{ guestReview['review_comment'] }}</p>

          <div class="row x-gap-30 y-gap-30 pt-20" v-if="guestReview['customer_review_images']">
            <div class="col-auto" v-for="image in image_decode(guestReview['customer_review_images'])">
              <a :href="assetsPath + 'storage/customer_review/' + image" :class="'review-gallery' + index"
                data-gallery="gallery2" style="display: block">
                <img :src="assetsPath + 'storage/customer_review/' + image" alt="image" class="rounded-4"
                  style="max-width: 120px;" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="row pt-30">
        <div class="col-auto" v-if="totalReviews > 5">
          <button class="button -md -outline-blue-1 text-blue-1" @click="getReviews()" v-if="showLoadMoreBtn">
            Show more..<div class="icon-arrow-top-right ml-15"></div>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";

export default {
  props: {
    searchHotelId: Number,
    searchCityId: Number,
    searchCityName: String,
    searchHotelCityName: String,
    searchCountryCode: String,
    searchCheckInDate: String,
    searchCheckOutDate: String,
    searchRoomUsers: String,
    path: String,
    currentUrl: String,
    defaultDate: Boolean,
    userId: Number,
    wishlistHotel: Object,
    guestNationality: String,
    guestNationalityCountryCode: String,
    clientIp: String,
  },
  data: function () {
    return {
      modifySearch: window.innerWidth<570? false:true,
      isOverviewVisible: false,
      windowWidth: window.innerWidth,
      hotelContent: null,
      homePagePath: "",
      assetsPath: "",
      hotelRooms: [],
      roomAmenities: null,
      isLoading: true,
      isLoadingHotel: true,
      totalHotels: 0,
      hotelDetail: null,
      amenitiesCount: 0,
      minRoomPrice: 0,
      defaultCurrency: "₹",
      searchSessionId: null,
      inputs: null,
      hotelRoomDetails: null,
      isHotelWishlist: null,
      images: [],
      guestReviews: [],
      totalReviews: 0,
      totalReviewsCount: 0,
      totalLocationRatings: 0,
      totalStaffRatings: 0,
      totalCleanlinessRatings: 0,
      totalValueForMoneyRatings: 0,
      totalComfortRatings: 0,
      totalFacilitesRatings: 0,
      totalFreeWifiRatings: 0,
      hotelIdForReview: null,
      pageLength: 5,
      page: 1,
      showLoadMoreBtn: true,
      hotelRoomList: [],
      hotelImages: [],
      totalRooms: 1,
      totalNights: 1,
      GuestNationality: null,
      GuestNationalityCountryCode: null,
      hotelRoomDetailsError: null,
    };
  },
  computed: {},
  mounted: function () {
    this.homePagePath = window.hotePagePath;
    this.assetsPath = window.assetsPath;
    this.GuestNationality = this.guestNationality ? this.guestNationality : null;
    this.GuestNationalityCountryCode = this.guestNationalityCountryCode ? this.guestNationalityCountryCode : null;

    this.defaultCurrency = window.defaultCurrency;
    this.loadData(
      this.searchHotelId,
      this.searchCityId,
      this.searchCityName,
      this.searchHotelCityName,
      this.searchCountryCode,
      this.searchCheckInDate,
      this.searchCheckOutDate,
      this.searchRoomUsers,
      this.GuestNationality,
      this.GuestNationalityCountryCode
    );
    // SCALTEN LOADING
    this.isHotelWishlist = this.wishlistHotel ? this.wishlistHotel.hotel_id : null;

    var checkInDate = moment(this.searchCheckInDate, "DD/MM/YYYY");
    var checkOutDate = moment(this.searchCheckOutDate, "DD/MM/YYYY");
    this.totalNights = checkOutDate.diff(checkInDate, "days");

    const roomUsers = JSON.parse(this.searchRoomUsers);
    this.totalRooms = roomUsers.length;
  },
  beforeMount() { },
  methods: {
    loadData: function (
      searchHotelId,
      searchCityId,
      searchCityName,
      searchHotelCityName,
      searchCountryCode,
      searchCheckInDate,
      searchCheckOutDate,
      searchRoomUsers,
      guestNationality,
      guestNationalityCountryCode
    ) {
      this.isLoading = true;

      // Get Hotel Details
      axios.post(`/hotels/${searchHotelId}`, {}).then((response) => {
        this.hotelContent = response.data.data;
        this.isLoadingHotel = false;
        this.hotelImages = response.data.data.images.filter(function (item) {
          return item.image_tag != 'Featured Image';
        }).map(function (item) {
          return item.image_url['1000px'] || item.image_url['350px'];
        });
        this.hotelContent.mainImage = response.data.data.images.filter(function (item) {
          return item.image_tag == 'Featured Image';
        }).map(function (item) {
          return item.image_url['1000px'] || item.image_url['350px'];
        })[0];


        this.images = response.data.data.images.map(item => item.image_url['1000px'] || item.image_url['350px']);
        this.roomAmenities = Array.from(new Set(response.data.data.rooms.map(item => item.amenities).flat()));
        // console.log(this.hotelContent);
      });

      axios
        .post("/get-hotel-details", {
          id: searchHotelId,
          cityId: searchCityId,
          cityName: searchCityName,
          hotelCityName: searchHotelCityName,
          countryCode: searchCountryCode,
          checkInDate: searchCheckInDate,
          checkOutDate: searchCheckOutDate,
          roomUsers: searchRoomUsers,
          guestNationality: guestNationality,
          guestNationalityCountryCode: guestNationalityCountryCode
        })
        .then((response) => {
          let hotelRoomDetails = response.data.data.hotelRoomDetails;
          if (!hotelRoomDetails.Error) {
            this.hotelRooms = hotelRoomDetails["RoomDetails"]["RoomDetail"];
            this.hotelRoomList = hotelRoomDetails.Hotelwiseroomcount == 1 ? [this.hotelRooms] : this.hotelRooms;

            if (hotelRoomDetails.Hotelwiseroomcount == 1) {
              let totalRent = 0;
              if (this.hotelRooms.TotalRooms > 1) {
                var allRoomsRent = this.hotelRooms.TotalRate.split("|");
                var roomRent = allRoomsRent.reduce((sum, a) => sum + parseFloat(a), 0);
                totalRent = roomRent + this.hotelRooms.Tax;
              } else {
                totalRent = parseFloat(this.hotelRooms.TotalRate) + parseFloat(this.hotelRooms.Tax);
              }

              this.minRoomPrice = parseFloat(totalRent);
              this.hotelRooms.totalRent = totalRent;
            } else {
              this.hotelRooms = this.hotelRooms.map((room, key) => {
                let totalRent = 0;
                if (room.TotalRooms > 1) {
                  var allRoomsRent = room.TotalRate.split("|");
                  var roomRent = allRoomsRent.reduce((sum, a) => sum + parseFloat(a), 0);
                  totalRent = roomRent + room.Tax;
                } else {
                  totalRent = parseFloat(room.TotalRate) + parseFloat(room.Tax);
                }
                if (key === 0) {
                  this.minRoomPrice = parseFloat(totalRent);
                }
                if (totalRent < this.minRoomPrice) {
                  this.minRoomPrice = parseFloat(totalRent);
                }
                room.totalRent = totalRent;
                return room;
              });
            }
          } else {
            this.hotelRoomDetailsError = hotelRoomDetails.Error;
          }

          // if (typeof hotelDetail["RoomAmenities"] == "string") {
          //   this.roomAmenities = hotelDetail["RoomAmenities"].split(",");
          //   let roomAmenities = [];
          //   if (this.roomAmenities.length > 6) {
          //     for (let index = 0; index <= 6; index++) {
          //       roomAmenities.push(this.roomAmenities[index]);
          //     }
          //     this.roomAmenities = roomAmenities;
          //   }
          // } else {
          //   this.roomAmenities = hotelDetail["RoomAmenities"];
          // }

          // this.hotelDetail = hotelDetail;

          // if(hotelDetail['Images'] != undefined){
          //   if (hotelDetail['Images']['Image'] != undefined ) {
          //     this.hotelImages = hotelDetail['Images']['Image'];
          //   }
          // }
          this.searchSessionId = response.data.data.search_session_id;
          this.inputs = response.data.data.inputs;

          if (!hotelRoomDetails.Error) {
            this.hotelRoomDetails = response.data.data.hotelRoomDetails;
          }

          // if (typeof hotelDetail["HotelAmenities"] == "string") {
          //   this.hotelAmenities = hotelDetail["HotelAmenities"].split(",");
          //   let hotelAmenities = new Array();
          //   if (this.hotelAmenities.length > 6) {
          //     for (let index = 0; index <= 6; index++) {
          //       hotelAmenities.push(this.hotelAmenities[index]);
          //     }
          //     this.hotelAmenities = hotelAmenities;
          //   }
          // } else {
          //   this.hotelAmenities = hotelDetail["HotelAmenities"];
          // }

          // if (hotelDetail['Images'] != undefined) {
          //   if (hotelDetail['Images']['Image'] != undefined) {
          //     this.images.push(this.hotelDetail["Images"]["Image"]);
          //   }
          // }

          setTimeout(() => {
            GLightbox({
              selector: ".js-gallery",
              touchNavigation: true,
              loop: false,
              autoplayVideos: true,
            });
            this.initMap(this.hotelContent, this.hotelRoomDetails);
          }, 100);

          this.isLoading = false;
          this.hotelIdForReview = this.hotelContent["property_id"];
          this.customerReviews(this.hotelContent["property_id"]);
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    image_decode: function (images) {
      return JSON.parse(images);
    },

    formateDate: function (date) {
      return moment(date).format("MMM YYYY");
    },

    customerReviews: function (hotelId) {
      axios.post('/get-customer-reviews', {
        hotelId: hotelId,
      })
        .then((response) => {
          this.guestReviews = response.data.data.customer_reviews['data'];
          this.totalReviewsCount = response.data.data.total_ratings;
          this.totalLocationRatings = response.data.data.location;
          this.totalStaffRatings = response.data.data.staff;
          this.totalCleanlinessRatings = response.data.data.cleanliness;
          this.totalValueForMoneyRatings = response.data.data.value_for_money;
          this.totalComfortRatings = response.data.data.comfort;
          this.totalFacilitesRatings = response.data.data.facilites;
          this.totalFreeWifiRatings = response.data.data.free_wifi;
          this.totalReviews = response.data.data.total_reviews;

          setTimeout(() => {
            for (let i = 0; i < this.guestReviews.length; i++) {
              GLightbox({
                selector: ".review-gallery" + i,
                touchNavigation: true,
                loop: false,
                autoplayVideos: true,
              });
            }
          }, 100);
        })
        .catch(function (error) {
        });
    },

    displayPropertyRate: function (price) {
      return window.convertToSelectedCurrency(price);
    },

    getReviews: function () {
      axios.post('/get-customer-reviews-data', {
        hotelId: this.hotelIdForReview,
        page: this.page,
        pageLength: this.pageLength
      })
        .then((response) => {
          this.page = this.page + 1;
          let reviews = response.data.data.customer_reviews;
          if (reviews.length > 0) {
            $.each(reviews, function (indexInArray, valueOfElement) {
              this.guestReviews.push(reviews[indexInArray]);
            });
          } else {
            this.showLoadMoreBtn = false;
          }
        })
        .catch(function (error) {
          window.fnToast(error, "error");
        });
    },

    initMap: function (hotelContent, hotelRoomDetails) {
      if (!document.querySelector(".js-map")) return;

      const map = new google.maps.Map(document.querySelector(".js-map"), {
        zoom: 12,
        center: {
          lat: parseFloat(hotelContent.latitude),
          lng: parseFloat(hotelContent.longitude),
        },
      });

      const locations = [
        {
          lat: parseFloat(hotelContent.latitude),
          lng: parseFloat(hotelContent.longitude),
        },
      ];

      class HTMLMapMarker extends google.maps.OverlayView {
        constructor(args) {
          super();
          this.latlng = args.latlng;
          this.html = args.html;
          this.setMap(args.map);
        }

        createDiv() {
          this.div = document.createElement("div");
          this.div.style.position = "absolute";

          if (this.html) {
            this.div.innerHTML = this.html;
          }
          google.maps.event.addDomListener(this.div, "click", (event) => {
            google.maps.event.trigger(this, "click");
          });
        }

        appendDivToOverlay() {
          const panes = this.getPanes();
          panes.overlayMouseTarget.appendChild(this.div);
        }

        positionDiv() {
          const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
          if (point) {
            this.div.style.left = `${point.x}px`;
            this.div.style.top = `${point.y}px`;
          }
        }

        draw() {
          if (!this.div) {
            this.createDiv();
            this.appendDivToOverlay();
          }
          this.positionDiv();
        }

        remove() {
          if (this.div) {
            this.div.parentNode.removeChild(this.div);
            this.div = null;
          }
        }

        getVisible() {
          return this.latlng;
        }

        getPosition() {
          return new google.maps.LatLng(this.latlng);
        }

        getDraggable() {
          return false;
        }
      }

      const contentString = `<div class="mapItem d-flex">
        <img src="${this.hotelContent["mainImage"]}" alt="image" class="mapItem__img size-100 rounded-4" onerror="this.src='${this.assetsPath}front-assets/img/general/noimage.gif'">
        <div class="mapItem__content d-flex flex-column justify-between ml-15">
          <div>
            <div class="fw-500">${this.hotelContent["name"]}</div>
            <div class="d-flex items-center mt-10">${this.displayRating(this.hotelContent['rating'])}
            </div>
          </div>
        </div>
      </div>`;

      const markers = locations.map((location) => {
        const marker = new HTMLMapMarker({
          latlng: location,
          map: map,
          html: `${hotelRoomDetails ? (hotelRoomDetails.Price ? `<div class="mapMarker bg-white rounded-100 border-dark-1 px-20 py-10">
            <div class="text-14 fw-500">${this.defaultCurrency} ${this.displayPropertyRate(hotelRoomDetails ? (hotelRoomDetails.Price ? hotelRoomDetails.Price : 0) : 0)}</div>
          </div>` : `<svg fill="#78909C" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 width="35px" height="35px" viewBox="0 0 395.71 395.71"
	 xml:space="preserve">
<g>
	<path d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738
		c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388
		C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191
		c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z"/>
</g>
</svg>`) : `<svg fill="#78909C" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 width="35px" height="35px" viewBox="0 0 395.71 395.71"
	 xml:space="preserve">
<g>
	<path d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738
		c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388
		C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191
		c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z"/>
</g>
</svg>`} `,
        });

        const infowindow = new google.maps.InfoWindow({
          content: contentString,
        });

        google.maps.event.addListener(map, 'click', function () {
          infowindow.close()
        });

        marker.addListener("click", () => {
          setTimeout(() => {
            infowindow.open({
              anchor: marker,
              map,
              shouldFocus: false,
            })
          }, 50);
        });

        return marker;
      });
           


      new markerClusterer.MarkerClusterer({ map, markers: markers });
    },

    displayRating: function (rating) {
      var html = '';
      for (var i = 0; i < rating; i++) {
        html += `<i class="icon-star text-yellow-1 text-10 ${i > 0 ? "ml-4" : ""}"></i>`;
      }
      return html;
    },

    wishList: function (isWishlistHotel) {
      if (!this.userId) {
        window.location.href = "/login";
      } else {
        axios
          .post("/wishlist", {
            hotelId: this.searchHotelId,
            userId: this.userId,
          })
          .then((response) => {
            if (isWishlistHotel) {
              this.isHotelWishlist = null;
            } else {
              this.isHotelWishlist = this.searchHotelId;
            }
            window.fnToast(response.data.message, "success");
          })
          .catch(function (error) {
            window.fnToast(error, "error");
          });
      }
    },

    setDefaultImage: function (e) {
      e.target.src = this.assetsPath + "front-assets/img/general/image_not_found-01.jpg";
    },
  },
};
</script>
