<template>
  <h3>Please Re-check Your Information you Provided...</h3>
  <div class="row x-gap-20 y-gap-20 pt-20">
    <div class="col-12">
      <div class="form-input">
        <span class="pt-3 pb-2 px-3 input-1">
          <span class="lh-1 text-16 text-light-1 input-2 d-block">Full Name</span>
          <span>{{ inputs["fullName"] }}</span>
        </span>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-input">
        <span class="pt-3 pb-2 px-3 input-1">
          <span class="lh-1 text-16 text-light-1 input-2 d-block">Email</span>
          <span>{{ inputs["email"] }}</span>
        </span>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-input">
        <span class="pt-3 pb-2 px-3 input-1">
          <span class="lh-1 text-16 text-light-1 input-2 d-block">Phone Number</span>
          <span>{{ inputs["phoneNumber"] }}</span>
        </span>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-input" v-if="inputs['gstNumber']">
        <span class="pt-3 pb-2 px-3 input-1">
          <span class="lh-1 text-16 text-light-1 input-2 d-block">GST Number</span>
          <span>{{ inputs["gstNumber"] }}</span>
        </span>
      </div>
    </div>
    <div class="col-12">
      <div class="form-input" v-if="inputs['specialRequest']">
        <span class="pt-3 pb-2 px-3 input-1">
          <span class="lh-1 text-16 text-light-1 input-2 d-block">Special Requests</span>
          <span>{{ inputs["specialRequest"] }}</span>
        </span>
      </div>
    </div>
  </div>

  <template v-for="n in inputs['guestDetails'].length">
    <div class="row x-gap-20 y-gap-20 pt-20 pax-information">
      <div class="col-md-12 pt-0">
        <label class="lh-1 text-16 text-dark-1 fw-600">Room {{ n }}</label>
      </div>
    </div>
    <template v-for="(guestDetail, i) in inputs['guestDetails'][n - 1]">
      <div class="row x-gap-20 y-gap-20 pt-20 pax-information">
        <div class="col-md-3 pt-0">
          <label v-if="!guestDetail.isChild" class="lh-1 text-16 text-light-1"> Adult {{ i + 1 }} </label>
          <label v-if="guestDetail.isChild" class="lh-1 text-16 text-light-1"> Child </label>
        </div>
        <div class="col-md-9 pt-0">
          <div class="row x-gap-20">
            <div class="col-md-4 mb-2">
              <select v-if="!guestDetail.isChild" v-model="guestDetail.title" required disabled>
                <option v-for="title in adultTitles" v-bind:value="title">
                  {{ title }}
                </option>
              </select>
              <select v-if="guestDetail.isChild" v-model="guestDetail.title" required disabled>
                <option v-for="title in chilrenTitles" v-bind:value="title">
                  {{ title }}
                </option>
              </select>
            </div>
            <div class="col-md-4 mb-2">
              <input type="text" v-model="guestDetail.firstName" placeholder="First Name" disabled />
            </div>
            <div class="col-md-4 mb-2">
              <input type="text" v-model="guestDetail.lastName" placeholder="Last Name" disabled />
            </div>
          </div>
        </div>
      </div>
    </template>
  </template>

  <div class="w-full h-1 bg-border mt-40 mb-40"></div>

  <div class="mt-30 mb-30 md:mt-32">
    <div class="mt-20">
      <ul class="list-disc y-gap-4 text-15 text-light-1">
        <li>
          The total amount you will be charged is: {{ defaultCurrency }} {{ totalBookingAmount }} (inc. GST)
        </li>
      </ul>
    </div>
  </div>

  <div class="row y-gap-20 items-center justify-between">
    <div class="col-auto">
      <div class="d-flex items-center">
        <div class="form-checkbox">
          <input type="checkbox" name="subscribe" v-model="subscribe" disabled />
          <div class="form-checkbox__mark">
            <div class="form-checkbox__icon icon-check"></div>
          </div>
        </div>
        <div class="text-14 lh-10 text-light-1 ml-10">
          Get access to members-only deals, just like the millions of other email subscribers
        </div>
      </div>
      <div class="text-14 text-light-1">
        By proceeding with this booking, I agree to VagaTrip
        <a v-bind:href="termsConditions">Terms of Use</a> and <a v-bind:href="privacyPolicy">Privacy Policy</a>.
      </div>
    </div>
  </div>

  <div class="row x-gap-20 y-gap-20 pt-20 items-center justify-end">
    <div class="col-auto pe-0">
      <form
        id="book_hotel"
        action="book-hotel"
        method="POST"
        class="d-flex justify-end"
        @submit.prevent="goBackBookHotel"
      >
        <button type="submit" class="button h-60 px-24 -dark-1 bg-blue-1 text-white w-auto content-justify-end">
          <div class="icon-arrow-left mr-15"></div>
          Back
        </button>
      </form>
    </div>

    <form
      id="booking_detail"
      action="submit-hotel-booking"
      method="POST"
      class="col-auto justify-start"
      @submit.prevent="submitBookingInfo"
    >
      <button type="submit" class="button h-60 px-24 -dark-1 bg-blue-1 text-white w-auto ml-15">
        Book Now
        <div class="icon-arrow-top-right ml-15"></div>
      </button>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    gstPercentage: Number,
    inputs: Object,
  },
  data: function () {
    return {
      privacyPolicy: "",
      termsConditions: "",
      adultTitles: ["Mr", "Ms", "Mrs"],
      chilrenTitles: ["Mr", "Ms"],
      subscribe: false,
      defaultCurrency: "₹",
      totalBookingAmount: 0,
      guestNationality:null,
      guestNationalityCountryCode:null,
    };
  },

  computed: {},
  mounted: function () {
    this.defaultCurrency = window.defaultCurrency;
    this.guestNationality = this.inputs.guestNationality;
    this.guestNationalityCountryCode = this.inputs.guestNationalityCountryCode;
    this.privacyPolicy = window.privacyPolicy;
    this.termsConditions = window.termsConditions;
    if (this.inputs["subscribe"] == "on") {
      this.subscribe = true;
    }
    setTimeout(() => {
      this.totalBookingAmount = window.convertToSelectedCurrency(this.inputs["bookingAfterPrice"]);
    }, 1000);
  },
  methods: {
    formatPriceWithGst(value) {
      var gst = ((value * this.gstPercentage) / 100).toFixed(2);
      var price = (value / 1).toFixed(2);
      var total = parseFloat(price) + parseFloat(gst);
      return total.toFixed(2);
    },
    submitBookingInfo() {
      const roomDetail = this.inputs["roomDetail"];
      const cancellationInformations = this.inputs["cancellationPolicy"];
      var token = $('meta[name="csrf-token"]').attr("content");
      $("#booking_detail").append(`
        <input type="hidden" name="_token" value="${token}" />
        <input type="hidden" name="searchSessionId" value="${this.inputs["searchSessionId"]}" />
        <input type="hidden" name="fullName" value="${this.inputs["fullName"]}" />
        <input type="hidden" name="email" value="${this.inputs["email"]}" />
        <input type="hidden" name="phoneNumber" value="${this.inputs["phoneNumber"]}" />
        <input type="hidden" name="gstNumber" value="${this.inputs["gstNumber"]}" />
        <input type="hidden" name="specialRequest" value="${this.inputs["specialRequest"]}" />
        <input type="hidden" name="hotelId" value="${this.inputs["hotelId"]}" />
        <input type="hidden" name="hotelCityName" value="${this.inputs["hotelCityName"]}" />
        <input type="hidden" name="cityId" value="${this.inputs["cityId"]}" />
        <input type="hidden" name="countryCode" value="${this.inputs["countryCode"]}" />
        <input type="hidden" name="cancellationPolicy" value='${JSON.stringify(cancellationInformations)}' />
        <input type="hidden" name="checkInDate" value="${this.inputs["checkInDate"]}" />
        <input type="hidden" name="checkOutDate" value="${this.inputs["checkOutDate"]}" />
        <input type="hidden" name="roomDetail" value='${JSON.stringify(roomDetail)}' />
        <input type="hidden" name="termsAndConditions" value='${this.inputs["termsAndConditions"]}' />
        <input type="hidden" name="guestNationality" value="${this.inputs.guestNationality}" />
        <input type="hidden" name="guestNationalityCountryCode" value="${this.inputs.guestNationalityCountryCode}" />
        <input type="hidden" name="discount" value='${this.inputs["discount"]}' />
        <input type="hidden" name="guestDetails" value='${JSON.stringify(this.inputs["guestDetails"])}' />`
      );
      $("#booking_detail").submit();
    },

    goBackBookHotel() {
      var token = $('meta[name="csrf-token"]').attr("content");
      $("#book_hotel").append(`
        <input type="hidden" name="_token" value="${token}" />
        <input type="hidden" name="hotelId" value="${this.inputs["hotelId"]}" />
        <input type="hidden" name="cityId" value="${this.inputs["cityId"]}" />
        <input type="hidden" name="hotelCityName" value="${this.inputs["hotelCityName"]}" />
        <input type="hidden" name="cityName" value="${this.inputs["cityName"]}" />
        <input type="hidden" name="countryCode" value="${this.inputs["countryCode"]}" />
        <input type="hidden" name="checkInDate" value="${this.inputs["checkInDate"]}" />
        <input type="hidden" name="checkOutDate" value="${this.inputs["checkOutDate"]}" />
        <input type="hidden" name="roomUsers" value='${JSON.stringify(this.inputs["roomUsers"])}' />
        <input type="hidden" name="roomType" value='${this.inputs["roomDetail"]["Type"]}' />
        <input type="hidden" name="bookingKey" value='${this.inputs["roomDetail"]["BookingKey"]}' />
        <input type="hidden" name="totalRooms" value='${this.inputs["roomDetail"]["TotalRooms"]}' />
        <input type="hidden" name="adults" value='${this.inputs["roomDetail"]["Adults"]}' />
        <input type="hidden" name="guestNationality" value="${this.inputs.guestNationality}" />
        <input type="hidden" name="guestNationalityCountryCode" value="${this.inputs.guestNationalityCountryCode}" />
        <input type="hidden" name="children" value='${this.inputs["roomDetail"]["Children"]}' />
        <input type="hidden" name="childrenAges" value='${this.inputs["roomDetail"]["ChildrenAges"]}' />
        <input type="hidden" name="totalRate" value='${this.inputs["roomDetail"]["TotalRate"]}' />
        <input type="hidden" name="searchSessionId" value='${this.inputs["searchSessionId"]}' />`
      );
      $("#book_hotel").submit();
    },
  },
};
</script>
