<template>
  <section id="rooms" class="pt-30">
    <div class="container">
      <div class="row pb-20">
        <div class="col-auto">
          <h3 class="text-22 fw-500">Available Rooms</h3>
        </div>
      </div>
      <template v-for="(hotelRoom, index) in allHotelRooms">
        <div v-if="index > 0" class="mt-20 sm:mt-5"></div>
        <div>

       
        <div class="bg-blue-2 rounded-4 px-30 py-30 sm:px-20 sm:py-20" v-if="windowWidth >570">
          <div class="row y-gap-30">
            <div class="col-xl-auto room-description-container">
              <div class="">
                <div class="text-18 fw-500 mt-10 capitalize">
                  {{ displayOneRoomInfo(hotelRoom["TotalRooms"], hotelRoom["Type"]) }}
                </div>
                <div class="y-gap-5 pt-5">
                  <div
                    v-for="roomFacility in getRoomFacilities(hotelRoom['TotalRooms'], hotelRoom['RoomDescription'])"
                    class="d-flex items-center"
                  >
                    <i class="icon-check text-20 mr-10"></i>
                    <div class="text-15">{{ roomFacility.trim() }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl">
              <div class="bg-white rounded-4 px-30 py-30">
                <div class="row y-gap-30">
                  <div class="col-lg col-md-6">
                    <div class="text-15 fw-500 mb-10">Your price includes:</div>
                    <div class="y-gap-5">
                      <div
                        v-for="facility in hotelRoom['BoardBasis'].split(',')"
                        class="d-flex items-center text-green-2"
                      >
                        <i class="icon-check text-12 mr-10"></i>
                        <div class="text-15">{{ displayOneRoomInfo(hotelRoom["TotalRooms"], facility) }}</div>
                      </div>
                      <div
                        v-if="
                          hotelRoom['CancellationPolicy'] && hotelRoom['CancellationPolicy']['Refundable'] === 'Yes'
                        "
                        class="d-flex items-center text-green-2"
                      >
                        <i class="icon-check text-12 mr-10"></i>
                        <div class="text-15">
                          Free cancellation till
                          {{ formatDate(hotelRoom["CancellationPolicy"]["TillDate"]) }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-auto col-md-6 border-left-light lg:border-none">
                    <div class="px-40 lg:px-0">
                      <div class="text-15 fw-500 mb-20">Sleeps</div>
                      <div class="d-flex items-center text-light-1">
                        <div v-for="n in totalAdults" class="icon-man text-24"></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-auto col-md-6 border-left-light lg:border-none text-right lg:text-left">
                    <div class="pl-40 lg:pl-0">
                      <div class="text-14 lh-14 text-light-1 mb-5">
                        {{ hotelRoom["TotalRooms"] }} room{{ hotelRoom["TotalRooms"] > 1 ? 's' : '' }},
                        {{ totalNights }} night{{ totalNights > 1 ? 's' : '' }} for
                      </div>
                      <div class="text-20 lh-14 fw-500">
                        {{ defaultCurrency }} {{ displayPropertyRate(hotelRoom.totalRent) }}
                        <h6 class="text-14 text-light-1">(inc. GST)</h6>
                      </div>
                      <form id="reserve_room_form" :action="bookHotelPath" method="POST">
                        <button
                          type="button"
                          class="button h-50 px-35 -dark-1 bg-blue-1 text-white mt-10"
                          @click="reserveRoom(hotelRoom)"
                        >
                          Reserve
                          <div class="icon-arrow-top-right ml-15"></div>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="mobile-room-list">
            <div class="room">
              <div class="pr-10">
                <b>  {{ displayOneRoomInfo(hotelRoom["TotalRooms"], hotelRoom["Type"]) }}</b> <br>
                    <div class="y-gap-5">
                      <div
                        v-for="facility in hotelRoom['BoardBasis'].split(',')"
                        class="d-flex items-center text-green-2"
                      >
                        <i class="icon-check text-12 mr-10"></i>
                        <div class="text-12">{{ displayOneRoomInfo(hotelRoom["TotalRooms"], facility) }}</div>
                      </div>
                      <div
                        v-if="
                          hotelRoom['CancellationPolicy'] && hotelRoom['CancellationPolicy']['Refundable'] === 'Yes'
                        "
                        class="d-flex items-center text-green-2"
                      >
                        <i class="icon-check text-12 mr-10"></i>
                        <div class="text-12">
                          Free cancellation till
                          {{ formatDate(hotelRoom["CancellationPolicy"]["TillDate"]) }}
                        </div>
                      </div>
                    </div>

                    <a
                    v-if="getRoomFacilities(hotelRoom['TotalRooms'], hotelRoom['RoomDescription']) && getRoomFacilities(hotelRoom['TotalRooms'], hotelRoom['RoomDescription']).length > 0"
                    class="text-12 text-green" v-on:click="hotelRoom.roomFacilitiesVisible=!hotelRoom.roomFacilitiesVisible">Room Facilities <i 
                      :class="{'icon-chevron-sm-down':hotelRoom.roomFacilitiesVisible==false,'icon-chevron-sm-up':hotelRoom.roomFacilitiesVisible==true}"
                       class="icon-chevron-sm-down text-10 mr-10"></i> </a>
                    <div
                    v-if="hotelRoom.roomFacilitiesVisible"
                    v-for="roomFacility in getRoomFacilities(hotelRoom['TotalRooms'], hotelRoom['RoomDescription'])"
                    class="d-flex items-center"
                  >
                    <i class="icon-check text-10 mr-10"></i>
                    <div class="text-10">{{ roomFacility.trim() }}</div>
                  </div>

              </div>
              
              <div>
                <small class="text-12 lh-12 text-light-1">
                  {{ hotelRoom["TotalRooms"] }} room{{ hotelRoom["TotalRooms"] > 1 ? 's' : '' }},
                  {{ totalNights }} night{{ totalNights > 1 ? 's' : '' }} for
                </small>
                <div class="text-16 lh-12 fw-700">
                  {{ defaultCurrency }} {{ displayPropertyRate(hotelRoom.totalRent) }}
                  <h6 class="text-12 text-light-1">(inc. GST)</h6>
                </div>
                <form id="reserve_room_form" :action="bookHotelPath" method="POST">
                  <button
                    type="button"
                    class="button py-5 sm:px-10 px-35 -dark-1 bg-blue-1 text-white mt-10"
                    @click="reserveRoom(hotelRoom)"
                  >
                    Reserve
                    <div class="icon-arrow-top-right ml-15"></div>
                  </button>
                </form>
              </div>
            </div>
        </div>  
      </div>

      </template>
    </div>
  </section>
</template>

<script>
import moment from "moment";
import $ from "jquery";

export default {
  props: {
    searchHotelId: Number,
    searchSessionId: String,
    allHotelRooms: Array,
    inputs: Object,
    hotelContent: Object,
    guestNationality: String,
    guestNationalityCountryCode: String,
  },
  data: function () {
    return {
      
    windowWidth: window.innerWidth,
      roomUsers: [
        {
          adults: 2,
          children: 0,
          childrenAge: [],
        },
      ],
      rooms: 1,
      totalAdults: 2,
      totalAdults: 0,
      hotelRooms: [],
      bookHotelPath: "",
      defaultCurrency: "₹",
      totalNights: 1,
    };
  },
  computed: {},
  mounted: function () {
    this.bookHotelPath = window.bookHotelPath;
    this.defaultCurrency = window.defaultCurrency;

    if (this.inputs && this.inputs.roomUsers) {
      this.roomUsers = JSON.parse(this.inputs.roomUsers);
    }
    this.rooms = this.roomUsers.length;
    this.totalAdults = this.roomUsers.reduce((total, item) => total + item.adults, 0);
    this.totalChildren = this.roomUsers.reduce((total, item) => total + item.children, 0);

    var checkInDate = moment(this.inputs.checkInDate, "DD/MM/YYYY");
    var checkOutDate = moment(this.inputs.checkOutDate, "DD/MM/YYYY");
    this.totalNights = checkOutDate.diff(checkInDate, "days");
    console.log('this.totalNights', this.totalNights);
  },

  methods: {
    displayPropertyRate: function (price) {
      return window.convertToSelectedCurrency(price);
    },

    formatDate(date) {
      return moment(date, "DD/MM/YYYY").format("DD MMM YYYY 23:59:59");
    },

    displayOneRoomInfo(totalRooms, description) {
      return totalRooms > 1 ? description.split("|")[0] : description;
    },

    getRoomFacilities(totalRooms, roomDescription) {
      if (typeof roomDescription == "string") {
        const facilities = this.displayOneRoomInfo(totalRooms, roomDescription);
        return facilities.split(",");
      }
    },

    reserveRoom(hotelRoom) {
      if (hotelRoom) {
        var token = $('meta[name="csrf-token"]').attr("content");
        $("#reserve_room_form").append(`
          <input type="hidden" name="_token" value="${token}" />
          <input type="hidden" name="hotelId" value="${this.searchHotelId}" />
          <input type="hidden" name="cityId" value="${this.hotelContent.city_code}" />
          <input type="hidden" name="hotelCityName" value="${this.inputs.hotelCityName}" />
          <input type="hidden" name="cityName" value="${this.inputs.cityName}" />
          <input type="hidden" name="countryCode" value="${this.inputs.countryCode}" />
          <input type="hidden" name="checkInDate" value="${this.inputs.checkInDate}" />
          <input type="hidden" name="checkOutDate" value="${this.inputs.checkOutDate}" />
          <input type="hidden" name="roomUsers" value='${this.inputs.roomUsers}' />
          <input type="hidden" name="roomType" value='${hotelRoom.Type}' />
          <input type="hidden" name="guestNationality" value="${this.guestNationality}" />
          <input type="hidden" name="guestNationalityCountryCode" value="${this.guestNationalityCountryCode}" />
          <input type="hidden" name="bookingKey" value='${hotelRoom.BookingKey}' />
          <input type="hidden" name="totalRooms" value='${hotelRoom.TotalRooms}' />
          <input type="hidden" name="adults" value='${hotelRoom.Adults}' />
          <input type="hidden" name="children" value='${hotelRoom.Children}' />
          <input type="hidden" name="childrenAges" value='${hotelRoom.ChildrenAges}' />
          <input type="hidden" name="totalRate" value='${hotelRoom.TotalRate}' />
          <input type="hidden" name="searchSessionId" value='${this.searchSessionId}' />`);

        $("#reserve_room_form").submit();
      }
    },
  },
};
</script>
