<template>
    <div class="image-viewer">
        <div class="collage">
            <div v-for="(image, index) in images" :key="index" class="image-container">
                <img :src="image.url" alt="Image">
            </div>
        </div>
        <div class="controls">
            <button @click="previousImage" :disabled="currentIndex === 0">Previous</button>
            <button @click="nextImage" :disabled="currentIndex === images.length - 1">Next</button>
        </div>
    </div>
</template>

<script>
export default {
    props: ['images'],
    data() {
        return {
            currentIndex: 0
        };
    },
    methods: {
        previousImage() {
            if (this.currentIndex > 0) {
                this.currentIndex--;
            }
        },
        nextImage() {
            if (this.currentIndex < this.images.length - 1) {
                this.currentIndex++;
            }
        }
    }
};
</script>

<style scoped>
.image-viewer {
    text-align: center;
}

.collage {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 10px;
}

.image-container {
    width: 200px;
    height: 200px;
    margin: 5px;
    overflow: hidden;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.controls {
    margin-top: 10px;
}
</style>
